// U+FEFF at the beginning

/*eslint unicode-bom: ["error", "always"]*/

const zbirnykTextArray = {
  2019: {
    "2019_zmist": {
      authors: {
        en: "",
        ua: "",
      },
      title: {
        en: "Table of contents",
        ua: "Зміст",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
      },
    },
    "2019_st1": {
      authors: {
        en: "Gladun A., Khala C.",
        ua: "Гладун А.Я., Хала К.О.",
      },
      title: {
        en: "Using of ontological models for formalized knowledge assessment",
        ua: "Використання онтологічних моделей для формалізованого оцінювання знань",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
      },
    },
    "2019_st2": {
      authors: {
        en: "Barkalov A.A., Titarenko L.A., Vizor Y.E., Matvienko A.V., Saburova S.A.",
        ua: "Баркалов А.А., Титаренко Л.А., Візор Я.Є., Матвієнко А.В., Сабурова С.А.",
      },
      title: {
        en: "Two fold state assignment for combined automation",
        ua: "Двійне кодування станів у суміщеному автоматі",
      },
      language: {
        en: "russian",
        ua: "російська",
      },
    },
    "2019_st3": {
      authors: {
        en: "Khodakovskyi M.",
        ua: "Ходаковський М.І.",
      },
      title: {
        en: "Research of the work of synaptic molecular machines",
        ua: "Дослідження роботи синаптичних молекулярних машин",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
      },
    },
    "2019_st4": {
      authors: {
        en: "Primin M., Nedayvoda I., Sutkovij P., Minov Y.",
        ua: "Прімін М.А., Недайвода І.В., Сутковой П.І., Мінов Ю.Д.",
      },
      title: {
        en: "Biosusceptometric studies of magnetic signals of small animals – physical models",
        ua: "Біосасептометричне дослідження магнітних сигналів дрібних тварин – фізичних моделей",
      },
      language: {
        en: "russian",
        ua: "російська",
      },
    },
    "2019_st5": {
      authors: {
        en: "Bezverbnyi I.A.",
        ua: "Безвербний І.А.",
      },
      title: {
        en: "On the issue of phoneme detection in the speech signal by the standing wave effect",
        ua: "До питання виділення фонем у мовному сигналі за допомогою ефекту стоячої хвилі",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
      },
    },
    "2019_st6": {
      authors: {
        en: "Lezin I.O., Kibec O.F.",
        ua: "Лєзін І.О., Кібець О.Ф.",
      },
      title: {
        en: "Selection of sings for searching objects on a texture background",
        ua: "Вибір ознак для пошуку об'єктів на фоні текстур",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
      },
    },
    "2019_st7": {
      authors: {
        en: "Lavrentyev V.M.",
        ua: "Лаврентьєв В.М.",
      },
      title: {
        en: "Decomposition of the pulse wave signal",
        ua: "Декомпозиція сигналу пульсової хвилі",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
      },
    },
    "2019_st8": {
      authors: {
        en: "Golovin O.M.",
        ua: "Головін О.М.",
      },
      title: {
        en: "Analysis of crowd using computer vision techniques",
        ua: "Аналіз натовпу людей із застосуванням методів комп'ютерного зору",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
      },
    },
    "2019_st9": {
      authors: {
        en: "Bahatskyi O.V.",
        ua: "Багацький О.В.",
      },
      title: {
        en: "Analysis of existing research support models for video information processing",
        ua: "Аналіз існуючих моделей підтримки наукових досліджень для обробки відеоінформації (огляд)",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
      },
    },
    "2019_st10": {
      authors: {
        en: "Lebyedyeva T.S., Shpylovyy P.B., Frolov I.O.",
        ua: "Лєбєдєва Т.С., Шпильовий П.Б., Фролов Ю.О.",
      },
      title: {
        en: "Influence of structure of aluminum films and pores formation regimes on nanoporous anodic alumina for optical sensors",
        ua: "Вплив структури плівок алюмінію та режимів формовки пор на нанопоруватий анодний оксид алюмінію для оптичних сенсорів",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
      },
    },
    "2019_st11": {
      authors: {
        en: "Hrusha V.",
        ua: "Груша В.М.",
      },
      title: {
        en: "Chlorophyll fluorometer classification and particularity of data analysis",
        ua: "Класифікація хлорофіл-флуорометрів та особливості аналізу отримуваних від них даних",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
      },
    },
    "2019_st12": {
      authors: {
        en: "Romanov V., Voronenko O., Galelyuka I.",
        ua: "Романов В.О., Вороненко О.В., Галелюка І.Б.",
      },
      title: {
        en: "Application of wireless sensor networks in food industry",
        ua: "Застосування бездротових сенсорних мереж в харчовій промисловості",
      },
      language: {
        en: "russian",
        ua: "російська",
      },
    },
    "2019_st13": {
      authors: {
        en: "Chichirin E.N., Sosnenko E.P.",
        ua: "Чічірін Є.М., Сосненко К.П.",
      },
      title: {
        en: "Neural network modeling of graphic recognition processes",
        ua: "Нейромережеве моделювання процесів розпізнавання графічних схем",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
      },
    },
    "2019_st14": {
      authors: {
        en: "Samolyuk T.",
        ua: "Самолюк Т.А.",
      },
      title: {
        en: "GAN neuro networks in the creation of new models",
        ua: "Нейромережі GAN в створенні нових моделей",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
      },
    },
    "2019_st15": {
      authors: {
        en: "Tupalo Y.",
        ua: "Тупало Я.О.",
      },
      title: {
        en: "Building a software architecture for the education system using the Zachman methodology",
        ua: "Побудова архітектури програмного засобу для освітньої системи за допомогою технології Захмана",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
      },
    },
    "2019_st16": {
      authors: {
        en: "Timashov Y.",
        ua: "Тимашов Є.О.",
      },
      title: {
        en: "Methodological principles of scenario modeling for the processes of a scientific experiment",
        ua: "Методологічні принципи сценарного моделювання для процесів наукового експерименту",
      },
      language: {
        en: "russian",
        ua: "російська",
      },
    },
    "2019_st17": {
      authors: {
        en: "Pisarenko V.G., Pisarenko J.V.",
        ua: "Писаренко В.Г., Писаренко Ю.В.",
      },
      title: {
        en: "General criteria and models of functioning of enterprise quality management systems in accordance with the recommendations of European standards ISO 9000",
        ua: "Загальні критерії та моделі функціонування систем управління якістю підприємств з урахуванням рекомендацій Європейських стандартів ISO 9000",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
      },
    },
    "2019_st18": {
      authors: {
        en: "Melnyk Y., Mudrenko M.",
        ua: "Мельник Є.В., Мудренко М.І.",
      },
      title: {
        en: "System of positioning for SQUID saseptometric research of magnetic properties of materials and biological tissues",
        ua: "Система позиціонування для СКВІД сасептометричних досліджень магнітних властивостей матеріалів та біологічних тканин",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
      },
    },
    "2019_st19": {
      authors: {
        en: "Antonova H.V., Kedich A.V., Kovyrova O.V.",
        ua: "Антонова Г.В., Кедич А.В., Ковирьова О.В.",
      },
      title: {
        en: "Internet of things and wireless smart networks in digital agriculture",
        ua: "Інтернет речей та бездротові смарт-мережі в точному землеробстві",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
      },
    },
  },
  2018: {
    "2018_zmist": {
      authors: {
        en: "",
        ua: "",
      },
      title: {
        en: "Table of contents",
        ua: "Зміст",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
      },
    },
    "2018_st1": {
      authors: {
        en: "Romanov V.O.",
        ua: "Романов В.О.",
      },
      title: {
        en: "Requirements to sensor nodes in Internet of Things networks",
        ua: "Вимоги до сенсорних вузлів у системах Інтернету речей",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
      },
    },
    "2018_st2": {
      authors: {
        en: "Primin M.A., Nedayvoda I.V., Lugovskij S.P.",
        ua: "Прімін М.А., Недайвода І.В., Луговський С.П.",
      },
      title: {
        en: "Uncontact investigations and analysis of magnetic signals at introduction of lead nanoparticles at the skin of small animals",
        ua: "Безконтактні дослідження і аналіз магнітних сигналів при введенні наночастинок свинцю на шкіру дрібних тварин",
      },
      language: {
        en: "russian",
        ua: "російська",
      },
    },
    "2018_st3": {
      authors: {
        en: "Mudrenko M.I., Melnyk E.V.",
        ua: "Мудренко М.І., Мельник Є.В.",
      },
      title: {
        en: "Creation and testing modernized measuring probes with overconducting antennas",
        ua: "Створення та тестування модернізованих вимірювальних зондів з надпровідними антенами",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2018_st4": {
      authors: {
        en: "Hrusha V.M.",
        ua: "Груша В.М.",
      },
      title: {
        en: "Elements of intelligent service-oriented information technology for sensors data acquisition and prosessing",
        ua: "Елементи інтелектуальної сервіс-орієнтованої інформаційної технології збору та обробки даних від сенсорів",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2018_st5": {
      authors: {
        en: "Lebyedyeva T.S., Shpylovyy P.B., Frolov Yu.V.",
        ua: "Лебєдєва Т.С., Шпильовий П.Б, Фролов Ю.В.",
      },
      title: {
        en: "Manufacturing and investigation of thin-film aluminium-alumina nanostructures",
        ua: "Виготовлення і дослідження тонкоплівкових наноструктур алюміній – анодний оксид алюмінію",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2018_st6": {
      authors: {
        en: "Voronenko О.V.",
        ua: "Вороненко О.В.",
      },
      title: {
        en: "Smart wireless sensor for evaluation of the state of plants",
        ua: "Бездротовий інтелектуальний сенсор для оцінки стану рослин",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2018_st7": {
      authors: {
        en: "Galelyuka I.B.",
        ua: "Галелюка І.Б.",
      },
      title: {
        en: "Modeling and estimating parameters of wireless sensor network for medical purpose",
        ua: "Моделювання та оцінювання параметрів бездротової сенсорної мережі медичного призначення",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2018_st8": {
      authors: {
        en: "Antonova G.V., Kovyrova O.V.",
        ua: "Антонова Г.В., Ковирьова О.В.",
      },
      title: {
        en: "Wireless technologies as part of the agricultural digitization",
        ua: "Бездротові технології як ланка цифровізації сільського господарства",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2018_st9": {
      authors: {
        en: "Khodakovskyi M.I., Verbitskiy V.G.",
        ua: "Ходаковський М.І., Вербицький В.Г.",
      },
      title: {
        en: "Study of processes of storage of information in genetic complexes of neurons",
        ua: "Дослідження процесів зберігання інформації в генних комплексах нейронів",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2018_st10": {
      authors: {
        en: "Mashkina M.V., Tymashov O.O.",
        ua: "Машкіна М.В., Тимашов О.О.",
      },
      title: {
        en: "Agency-organized programming for the exercise of great data sheets",
        ua: "Використання методів агентно-орієнтованого програмування при оперуванні великими обсягами даних",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2018_st11": {
      authors: {
        en: "Barkalov A.A., Titarenko L.A., Vizor Y.E., Matvienko A.V.",
        ua: "Баркалов А.А., Титаренко Л.А., Візор Я.Є., Матвієнко А.В.",
      },
      title: {
        en: "Encoding output variables in combined automation",
        ua: "Кодування вихідних змінних у суміщеному автоматі",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2018_st12": {
      authors: {
        en: "Sabelnikov P.Yu.",
        ua: "Сабельніков П.Ю.",
      },
      title: {
        en: "Method of multi-format access to fragments of two-dimensional data arrays",
        ua: "Метод багатоформатного доступу до фрагментів двовимірних масивів даних",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2018_st13": {
      authors: {
        en: "Chichirin E.N.",
        ua: "Чічірін Є.М.",
      },
      title: {
        en: "Intelligent methods in simulation of decision-making processes",
        ua: "Інтелектуальні методи в моделюванні процесів прийняття рішення",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2018_st14": {
      authors: {
        en: "Pisarenko V.G., Pisarenko J.V., Gavrylyuk O.M., Boiko A.G.",
        ua: "Писаренко В.Г., Писаренко Ю.В., Гаврилюк О.М., Бойко А.Г.",
      },
      title: {
        en: "About dynamics of information flows",
        ua: "Про динаміку інформаційних потоків",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2018_st15": {
      authors: {
        en: "Tupalo Y.O.",
        ua: "Тупало Я.О.",
      },
      title: {
        en: "Using machine learning methods in practice",
        ua: "Використання методів машинного навчання на практиці",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2018_st16": {
      authors: {
        en: "Bezverbnyi I.A.",
        ua: "Безвербний І.А.",
      },
      title: {
        en: "The cloud-based component to support the process of informatics discipline learning",
        ua: "Хмаро орієнтований компонент для підтримування процесу навчання інформатичних дисциплін",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2018_st17": {
      authors: {
        en: "Самолюк Т.А., Сосненко Є.П.",
        ua: "Samolyuk T.А., Sosnenko K.P.",
      },
      title: {
        en: "Semi-natural modeling aqueous control processes",
        ua: "Напівнатурне моделювання процесів контролю водної обстановки",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
  },
  2017: {
    "2017_zmist": {
      authors: {
        en: "",
        ua: "",
      },
      title: {
        en: "Table of contents",
        ua: "Зміст",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2017_st1": {
      authors: {
        en: "Palagin O., Velychko V., Malakhov K., Shchurov O.",
        ua: "Палагін О.В., Величко В.Ю., Малахов К.С., Щуров О.С.",
      },
      title: {
        en: "Personal research information system. About developing the methods for searching patent analogs of invention",
        ua: "Автоматизоване робоче місце наукового дослідника. До питання розробки методів пошуку аналогів патентної документації винаходу",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2017_st2": {
      authors: {
        en: "Semotyuk M.V., Bezverbnyi I.A.",
        ua: "Семотюк М.В., Безвербний І.А.",
      },
      title: {
        en: "Adaptive algorithm for allocation phonemes in speech signal",
        ua: "Адаптивний алгоритм виділення фонем у мовному сигналі",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2017_st3": {
      authors: {
        en: "Barkalov A.A., Titarenko L.A., Vizor Y.E., Matvienko A.V.",
        ua: "Баркалов А.А., Титаренко Л.А., Вызор Я.Э., Матвыэнко А.В.",
      },
      title: {
        en: "Reducing number of LUTs of combined microprogram automat",
        ua: "Зменшення числа елементів табличного типу в схемі суміщеного мікропрограмного автомату",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2017_st4": {
      authors: {
        en: "Primin M., Nedayvoda I., Burchak A., Sutkovij P., Minov Y.",
        ua: "Прімін М.А., Недайвода І.В., Бурчак А.В., Сутковий П.Г., Мінов Ю.Д.",
      },
      title: {
        en: "Uncontact measurements and analysis of magnetic signals of coal samples",
        ua: "Безконтактні дослідження і аналіз магнітних сигналів зразків вугільної речовини",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2017_st5": {
      authors: {
        en: "Bagatskiy V., Bagatskiy O.",
        ua: "Багацький В.О., Багацький О.В.",
      },
      title: {
        en: "Monitoring the quality of 220 V electrical network by using 'Yakist-E1' device",
        ua: "Моніторинг якості електроенергії у мережі 220 В за допомогою приладу 'Якість-Е1'",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2017_st6": {
      authors: {
        en: "Mudrenko M.I., Melnyk E.V.",
        ua: "Мудренко М.І., Мельник Є.В.",
      },
      title: {
        en: "Design and development of sensor modules to multisensor medical devices",
        ua: "Моделювання та розробка сенсорних модулів до мультисенсорних медичних приладів",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2017_st7": {
      authors: {
        en: "Lebyedyeva T., Shpylovyy P.",
        ua: "Лебєдєва Т.С., Шпильовий П.Б.",
      },
      title: {
        en: "Controlled technology of anodic alumina porous nanostructures",
        ua: "Контрольована технологія поруватих наноструктур на основі оксиду алюмінію",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2017_st8": {
      authors: {
        en: "Antonova H., Kovyrova O., Lavrentyev V.",
        ua: "Антонова Г.В., Ковирьова О.В., Лаврентьєв В.М.",
      },
      title: {
        en: "Graph-analytical method of analysis of fluorescence chlorophyll parameters",
        ua: "Графоаналітичний метод аналізу параметрів флуоресценції хлорофілу",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2017_st9": {
      authors: {
        en: "Hrusha V.M.",
        ua: "Груша В.М.",
      },
      title: {
        en: "Chlorophyll fluorometer data normalization and dimensionality reduction",
        ua: "Нормалізація та зменшення розмірності даних хлорофіл-флуорометрів",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2017_st10": {
      authors: {
        en: "Chichirin E.N.",
        ua: "Чічірін Є.М.",
      },
      title: {
        en: "Recognition of the structure of graphic images of block schemes",
        ua: "Розпізнавання структури графічних зображень блок-схем",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2017_st11": {
      authors: {
        en: "Samolyuk T.",
        ua: "Самолюк Т.А.",
      },
      title: {
        en: "Study of stochastic gradient methods for optimization of algorithms of learning artificial neural networks",
        ua: "Дослідження стохастичних градієнтних методів оптимізації алгоритмів навчання штучних нейронних мереж",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2017_st12": {
      authors: {
        en: "Romanov V.O., Galelyuka I.B., Ostapenko V.O.",
        ua: "Романов В.О., Галелюка І.Б., Остапенко В.О.",
      },
      title: {
        en: "Requirements to functional and informational safety of wireless sensor networks",
        ua: "Вимоги до забезпечення функціональної та інформаційної безпеки бездротових сенсорних мереж",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2017_st13": {
      authors: {
        en: "Khodakovskyi M.I.",
        ua: "Ходаковський М.І.",
      },
      title: {
        en: "Study of the processes of molecular devices working at the formation of memory in the neuron nucleus",
        ua: "Дослідження процесів роботи молекулярних пристроїв при формуванні пам'яті вядрі нейрону",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2017_st14": {
      authors: {
        en: "Skachko D.О., Tymashov O.O.",
        ua: "Скачко Д.О., Тімашов О.О.",
      },
      title: {
        en: "Decision-making based on the analysis of displacements within spatial objects",
        ua: "Прийняття рішень на основі аналізу переміщень у середині просторових об'єктів",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2017_st15": {
      authors: {
        en: "Pisarenko V., Pisarenko J., Koval O.",
        ua: "Писаренко В.Г., Писаренко Ю.В., Коваль О.С.",
      },
      title: {
        en: "About the development of automated informational system for ensure of the plant varieties examination",
        ua: "Про розвиток автоматизованої інформаційної системи забезпечення експертизи сортів рослин з урахуванням вимог ЄС",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2017_st16": {
      authors: {
        en: "Timashov E.",
        ua: "Тімашов Є.А.",
      },
      title: {
        en: "Technologies of virtual models in training of specialists",
        ua: "Технології віртуальних моделей в навчанні спеціалістів",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2017_st17": {
      authors: {
        en: "Tupalo Y.",
        ua: "Тупало Я.О.",
      },
      title: {
        en: "Architecture of expert support and decision making system",
        ua: "Архітектура системи підтримки та прийняття рішення",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2017_st18": {
      authors: {
        en: "Reznichenko R., Timashova L.",
        ua: "Резніченко Р.В., Тімашова Л.А.",
      },
      title: {
        en: "Intelligent technology of risk management of the virtual enterprise",
        ua: "Інтелектуальна технологія управління ризиками віртуального підприємства",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2017_st19": {
      authors: {
        en: "Malinovskyi B.N.",
        ua: "Малиновський Б.М.",
      },
      title: {
        en: "'Last step is the most difficult one'",
        ua: "'Останній крок – він найважчий'",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
  },
  2016: {
    "2016_zmist": {
      authors: {
        en: "",
        ua: "",
      },
      title: {
        en: "Table of contents",
        ua: "Зміст",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2016_st1": {
      authors: {
        en: "Palagin A.V., Petrenko N.G., Mitofanova A.E.",
        ua: "Палагін О.В., Петренко М.Г., Митрофанова А.Е.",
      },
      title: {
        en: "About building of ontology systems of different purposes",
        ua: "До питання побудови онтологічних систем різного призначення",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2016_st2": {
      authors: {
        en: "Barkalov A.A., Titarenko L.A., Vizor Y.E., Matvienko A.V.",
        ua: "Баркалов А.А., Титаренко Л.А., Візор Я.Е., Матвієнко А.В.",
      },
      title: {
        en: "Realization of combined finite state machine with FPGAs",
        ua: "Реалізація схеми суміщеного автомату в базисі FPGA",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2016_st3": {
      authors: {
        en: "Primin M., Nedayvoda I., Vasyliev V., Minov Y., Sutkovoj P., Lugovskoj S., Lubjanova I., Kotov A.",
        ua: "Прімін М.А., Недайвода І.В., Васильєв В.Е., Мінов Ю.Д., Сутковий П.Г., Луговский С.П., Лубянова І.П., Котов А.В.",
      },
      title: {
        en: "Application of uncontact magnetometric method for cholestasia investigation in experiment",
        ua: "Використання безконтактного магнітометричного методу для діагностики холестазу в експерименті",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2016_st4": {
      authors: {
        en: "Lebyedyeva T., Shpylovyy P.",
        ua: "Лебєдєва Т.С., Шпильовий П.Б.",
      },
      title: {
        en: "Increasing sensitivity of sensors based on surface plasmon resonance",
        ua: "Підвищення чутливості сенсорів, базованих на поверхневому плазмонному резонансі",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2016_st5": {
      authors: {
        en: "Lukash S.I., Budnic M.M., Frolov Yu., Vakal L., Lukash L.",
        ua: "Лукаш С.І., Будник М.М., Фролов Ю.О., Вакал Л.П., Лукаш Л.Л.",
      },
      title: {
        en: "A device for external respiration diagnostics",
        ua: "Прилад для діагностики зовнішнього дихання",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2016_st6": {
      authors: {
        en: "Brayko Yu. , Imamutdinova R.",
        ua: "Брайко Ю.О., Імамутдінова Р.Г.",
      },
      title: {
        en: "Principles of design of wireless sensor network concentrator on basis of smart devices",
        ua: "Принципи побудови концентратора для безпровідної сенсорної мережі на базі інтелектуальних приладів",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2016_st7": {
      authors: {
        en: "Antonova G., Kovyrova O.",
        ua: "Антонова Г.В., Ковирьова О.В.",
      },
      title: {
        en: "Elements of methodical support for testing biosensor prototypes on the test plants under influence of stress factors",
        ua: "Елементи методики випробувань дослідних зразків біосенсора на тестових культурах під впливом дії стресових факторів",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2016_st8": {
      authors: {
        en: "Kurzantseva L.I.",
        ua: "Курзанцева Л.І.",
      },
      title: {
        en: "On the construction of user interface using FPGA for knowledge-oriented systems",
        ua: "Про побудову інтерфейсу користувача для знання-орієнтованих систем з використанням ПЛІС",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2016_st9": {
      authors: {
        en: "Eliseeva E.",
        ua: "Єлісєєва О.В.",
      },
      title: {
        en: "About modification of the algorithm user distribution program for implementation it on CPLD",
        ua: "Про модифікацію алгоритму розподілу програми користувача для реалізації його на ПЛІС",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2016_st10": {
      authors: {
        en: "Bagatskiy A.V.",
        ua: "Багацький О.В.",
      },
      title: {
        en: "The instrumental complex for research algorithms for object boundaries",
        ua: "Інструментальний комплекс для дослідження алгоритмів виділення контурів об'єктів",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2016_st11": {
      authors: {
        en: "Sabelnikov P.Y.",
        ua: "Сабельніков П.Ю.",
      },
      title: {
        en: "The calculation of moments of inertia the contours of objects in images using vector operations",
        ua: "Обчислення моментів інерції контурів об'єктів у зображеннях з використанням векторних операцій",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2016_st12": {
      authors: {
        en: "Khodakovskyi M.I., Osinskyi V.I.",
        ua: "Ходаковський М.І., Осинський В.І.",
      },
      title: {
        en: "Study of recording of information on DNA complexes neuron",
        ua: "Дослідження процесів запису інформації на ДНК-комплексах нейрону",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2016_st13": {
      authors: {
        en: "Romanov V., Galelyuka I., Voronenko O., Hrusha V.",
        ua: "Романов В.О., Галелюка І.Б., Вороненко О.В., Груша В.М.",
      },
      title: {
        en: "New information technology for express-diagnostics of plant state under stresses",
        ua: "Нова інформаційна технологія експрес-оцінювання стану рослин в умовах дії стресових факторів",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2016_st14": {
      authors: {
        en: "Timashov E.",
        ua: "Тімашов Е.А.",
      },
      title: {
        en: "Methods and information technology development of mobile medical-diagnostic complex",
        ua: "Методи та інформаційна технологія розробки мобільних лікувально-діагностичних комплексів",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2016_st15": {
      authors: {
        en: "Zinchenko V., Tixomirov V., Zinchenko S., Potapenko A., Brovarska N.",
        ua: "Зінченко В.П., Тихомиров В.А., Зінченко С.В., Потапенко А.Ф., Броварска Н.І.",
      },
      title: {
        en: "The problem of modeling hardware and software environment of the complex of avionics for stages of design, testing and operation",
        ua: "Задачі моделювання апаратно-програмних середовищ комплексів авіоніки для етапів проектування, випробувань та експлуатації",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2016_st16": {
      authors: {
        en: "Chichirin E.N.",
        ua: "Чічірін Е.М.",
      },
      title: {
        en: "Subsystem processing and forming signal",
        ua: "Підсистема обробки і формування аудіо сигналів",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2016_st17": {
      authors: {
        en: "Kolesnik Yu., Fedak V.S.",
        ua: "Колесник Ю.С., Федак В.С.",
      },
      title: {
        en: "Methods features of fluorescent phytomonitoring city environment",
        ua: "Методичні особливості флуоресцентного фітомоніторингу довкілля міста",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2016_st18": {
      authors: {
        en: "Pisarenko J.",
        ua: "Писаренко Ю.В.",
      },
      title: {
        en: "About the components of intellectual support system for agronomic solutions CONTROL_AGROTEP",
        ua: "Про інтелектуальну систем підтримки агрономічних рішень КЕРУВАННЯ_АГРОТЕП",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2016_st19": {
      authors: {
        en: "Varava I.",
        ua: "Варава І.А.",
      },
      title: {
        en: "The scientific experiment control in multiphase environment",
        ua: "Керування складним науково-технічним експериментом у багатофазному середовищі",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2016_st20": {
      authors: {
        en: "Pisarenko V., Korneev S., Kuzko A.",
        ua: "Писаренко В.Г., Корніїв С.В., Кузько А.В.",
      },
      title: {
        en: "About actual expeditions to East Antarctida for monitoring possible sings of melting glaciers of continental shelf",
        ua: "Про актуальність експедиції у східну Антарктиду для моніторингу можливих ознак танення льодовиків континентального шельфу",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2016_st21": {
      authors: {
        en: "Tupalo Y.",
        ua: "Тупало Я.О.",
      },
      title: {
        en: "Using of the methods of decision making in mobility",
        ua: "Використання методів прийняття рішень в мобільності",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2016_st22": {
      authors: {
        en: "Qasem A.",
        ua: "Касім А.М.",
      },
      title: {
        en: "Architecture of ontological software module for knowledge-oriented geoinformation system of smart agriculture",
        ua: "Архітектура онтологічного програмного модуля для знання-орієнтованої геоінформаційної системи смарт землеробства",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
  },
  2015: {
    "2015_zmist": {
      authors: {
        en: "",
        ua: "",
      },
      title: {
        en: "Table of contents",
        ua: "Зміст",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2015_st1": {
      authors: {
        en: "Primin M., Nedayvoda I.",
        ua: "Прімін М.А., Недайвода І.В.",
      },
      title: {
        en: "Algorithm of analytical solution of magnetostatic inverse problem for dipolar field source",
        ua: "Алгоритм аналітичного рішення зворотної задачі магнітостатики для джерела поля дипольної моделі",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2015_st2": {
      authors: {
        en: "Vakal L.",
        ua: "Вакал Л.П.",
      },
      title: {
        en: "Genetic algorithms as a tool for solving of nonlinear boundary value problems",
        ua: "Генетичні алгоритми як інструмент розв'язання нелінійних крайових задач",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2015_st3": {
      authors: {
        en: "Khodakovskyi M.I., Osinskyi V.I.",
        ua: "Ходаковський М.І., Осинський В.І.",
      },
      title: {
        en: "Investigation of processes of acquisition of knowledges during the formation of memory controlled with nuclear neuron",
        ua: "Дослідження процесів отримання знань при формуванні пам'яті під контролем ядра нейрона",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2015_st4": {
      authors: {
        en: "Barkalov A.A., Titarenko L.A., Vizor Y.E., Matvienko A.V.",
        ua: "Барлаков А.А., Титаренко Л.А., Візор Я.Е., Матвієнко А.В.",
      },
      title: {
        en: "Synthesis of combined finite state machine with FPGAs",
        ua: "Синтез суміщеного мікропрограмного автомату в базисі FPGA",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2015_st5": {
      authors: {
        en: "Chichirin E.N.",
        ua: "Чічірін Е.М.",
      },
      title: {
        en: "Reconfigurable the Viterbi decoder on the basis of FPGA Xilinx",
        ua: "Структура декодера Вітербі, що перебудовується, в базисі ПЛІС Xilinx",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2015_st6": {
      authors: {
        en: "Kalenchuk-Porkhanova A.",
        ua: "Каленчук-Порханова А.О.",
      },
      title: {
        en: "Mathematical modelling of the transfer pollutions in the water-ways",
        ua: "Математичне моделювання переносу забруднень у водостоках",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2015_st7": {
      authors: {
        en: "Lebyedyeva T., Minov Yu., Sutkoviy P., Frolov Yu., Shpylovyy P.",
        ua: "Лебедєва Т.С., Мінов Ю.Д., Сутковий П.Г., Фролов Ю.А., Шпилевий П.Б.",
      },
      title: {
        en: "Development of waveguide sensors basing on the thin-film structures of nanoporous anodic alumina-alumina",
        ua: "Реалізація хвилепровідних сенсорів на тонкоплівкових структурах нанопористий анодний оксид алюмінію–алюміній",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2015_st8": {
      authors: {
        en: "Chernetsky V.",
        ua: "Чернецький В.В.",
      },
      title: {
        en: "Electromagnetic method of temporal developing transformation of physical quantities",
        ua: "Електромагнітний метод часового розгортуючого вимірювання фізичних величин",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2015_st9": {
      authors: {
        en: "Merzvynskyi A., Chaykovskyy I., Merzvynskyi P.",
        ua: "Мержвинський А.О., Чайковський І.А., Мержвинський П.А.",
      },
      title: {
        en: "Development of methods and technical means of non-invasive photohemometry",
        ua: "Розробка методик та технічних засобів неінвазивної фотогемометрії",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2015_st10": {
      authors: {
        en: "Lukash S.I., Budnic M.M., Horobets I.M.",
        ua: "Лукаш С.І., Будник М.М., Горобець І.М.",
      },
      title: {
        en: "Automated hardware and software system for the assessment of human health",
        ua: "Автоматизований програмно-апаратний комплекс для оцінки стану здоров'я людини",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2015_st11": {
      authors: {
        en: "Antonova G., Kovyrova O., Lavrentyev V.",
        ua: "Антонова Г.В., Ковирьова О.В., Лаврентьєв В.М.",
      },
      title: {
        en: "Research of chlorophyll fluorescence induction in dependence on temperature and sensor location on the plant",
        ua: "Дослідження залежності індукції флуоресценції хлорофілу від температури та розташування сенсора на рослині",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2015_st12": {
      authors: {
        en: "Palagin O., Sarakhan Ye., Prysyazhnyuk O., Korovko I.",
        ua: "Палагін О.В., Сарахан Є.В., Присяжнюк О.І., Коровко І.І.",
      },
      title: {
        en: "Information features of main indicators of method of chlorophyll fluorescence induction of sugar beet plants",
        ua: "Інформаційні можливості основних показників кривої індукції флуоресценції хлорофілу на прикладі цукрових буряків",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2015_st13": {
      authors: {
        en: "Hrusha V.",
        ua: "Груша В.М.",
      },
      title: {
        en: "Analysis of data measured by portable fluorometer 'Floratest'",
        ua: "Обробка результатів експериментальних досліджень, проведених з використанням портативного флуорометра 'Флоратест'",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2015_st14": {
      authors: {
        en: "Romanov V., Imamutdinova R.",
        ua: "Романов В.О., Імамутдінова Р.Г.",
      },
      title: {
        en: "Study of chlorophyll fluorescence induction change by optical sensor during of long-term stay on living plant leaf",
        ua: "Вивчення зміни індукції флуоресценції хлорофілу при довготривалому розташуванні оптичного сенсора на листу живої рослини",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2015_st15": {
      authors: {
        en: "Sosnenko E.P.",
        ua: "Сосненко Е.П.",
      },
      title: {
        en: "Comparative analysis of construction of the decoder of the convolution code",
        ua: "Порівняльний аналіз середовищ реалізації декодера коду, який згортається",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2015_st16": {
      authors: {
        en: "Samolyuk T.",
        ua: "Самолюк Т.А.",
      },
      title: {
        en: "Deep neural network elements and their implementation in FPGA Xilinx",
        ua: "Глибокі нейромережі та елементи їх реалізації в ПЛІС FPGA",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2015_st17": {
      authors: {
        en: "Galelyuka I.",
        ua: "Галелюка І.Б.",
      },
      title: {
        en: "Modelling of wireless sensor networks",
        ua: "Моделювання бездротових сенсорних мереж",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2015_st18": {
      authors: {
        en: "Golovin O.",
        ua: "Головін О.М.",
      },
      title: {
        en: "Quality of service support in multimedia smart camera networks",
        ua: "Забезпечення якості послуг у мультимедійних мережах з інтелектуальними відеокамерами",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
  },
  2014: {
    "2014_zmist": {
      authors: {
        en: "",
        ua: "",
      },
      title: {
        en: "Table of contents",
        ua: "Зміст",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2014_st1": {
      authors: {
        en: "Primin M., Nedayvoda I.",
        ua: "Прімін М.А., Недайвода І.В.",
      },
      title: {
        en: "Using of principal component method for weak magnetic signals processing",
        ua: "Застосування методу головних компонент при обробці слабких магнітних сигналів",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2014_st2": {
      authors: {
        en: "Bagatsky V., Bagatsky O.",
        ua: "Багацький В.О., Багацький О.В.",
      },
      title: {
        en: "The device for measuring quality of electricity in consumer's 220 V electrical network",
        ua: "Прилад для визначення якості електроенергії в мережі 220 В у споживача",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2014_st3": {
      authors: {
        en: "Bednov M., Lebyedyeva T., Shpylovyy P.",
        ua: "Бєднов М.В., Лебєдєва Т.С., Шпильовий П.Б.",
      },
      title: {
        en: "Modelling of the optical sensors with nanostructered coatings",
        ua: "Моделювання оптичних сенсорів з наноструктурованими покриттями",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2014_st4": {
      authors: {
        en: "Chernetsky V., Brayko Yu., Imamutdinova R.",
        ua: "Чернецький В.В., Брайко Ю.О., Імамутдінова Р.Г.",
      },
      title: {
        en: "Computer device for measuring the dynamic parameters of the cardiovascular system",
        ua: "Комп'ютерний прилад для вимірювання динамічних процесів серцево-судинної системи",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2014_st5": {
      authors: {
        en: "Degtjaruk V.",
        ua: "Дегтярук В.І.",
      },
      title: {
        en: "Pulse processes in the heart-vascular system of humans and their using for diagnostics",
        ua: "Пульсові процеси в серцево-судинній системі людини та іх використання для діагностики",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2014_st6": {
      authors: {
        en: "Romanov V., Palagin O., Galelyuka I., Voronenko O.",
        ua: "Романов В.О., Палагін О.В., Галелюка І.Б., Вороненко О.В.",
      },
      title: {
        en: "Wireless sensor network for precision agriculture and ecological monitoring",
        ua: "Безпровідна сенсорна мережа для прецизійного землеробства та екологічного моніторингу",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2014_st7": {
      authors: {
        en: "Pletinka O., Gorin F., Potapenko A., Chymakova N.",
        ua: "Плетінка О.В., Горін Ф.М., Потапенко А.Ф., Чумакова Н.Ф.",
      },
      title: {
        en: "To modeling parameters of communication microsatellite subsystems using virtual environment",
        ua: "До питання моделювання параметрів систем зв'язку мікросупутника з використанням підсистем віртуального оточення",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2014_st8": {
      authors: {
        en: "Zinchenko S.",
        ua: "Зінченко С.В.",
      },
      title: {
        en: "Highly productive real-time systems for the large-scale applications",
        ua: "Високопродуктивні системи реального часу для великомасштабних застосувань",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2014_st9": {
      authors: {
        en: "Wei Li, Zinchenko V., Borisov V., Brovarska N., Chymakov V.",
        ua: "Лі Вей, Зінченко В.П., Борисов В.В., Броварська Н.І., Чумаков В.Г.",
      },
      title: {
        en: "Forming of software environments for support of tasks of automation of scientific researchers",
        ua: "Формування програмних середовищ для підтримки задач автоматизації наукових досліджень",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2014_st10": {
      authors: {
        en: "Lukash S., Budnic M.",
        ua: "Лукаш С.І., Будник М.М.",
      },
      title: {
        en: "Model of influence of physical activity on change of the human functional state",
        ua: "Моделювання впливу фізичного навантаження на зміну функціонального стану людини",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2014_st11": {
      authors: {
        en: "Samolyuk T., Los V.",
        ua: "Самолюк Т.А., Лось В.С.",
      },
      title: {
        en: "Solving the range equations of target detection in noise direction-finding mode in a system of hydroacoustic calculations",
        ua: "Рішення рівнянь дальності виявлення цілі при шумопеленгуванні в системі гідроакустичних розрахунків",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2014_st12": {
      authors: {
        en: "Bezverbnyi I.",
        ua: "Безвербний І.А.",
      },
      title: {
        en: "The frequency phase analysis algorithmic base in the sonar systems",
        ua: "Алгоритмічна база частотно-фазового аналізу у системах гідролокації",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2014_st13": {
      authors: {
        en: "Hrusha V.",
        ua: "Груша В.М.",
      },
      title: {
        en: "Information technologies for research of chlorophyll fluorescence induction",
        ua: "Інформаційні технології для дослідження індукції флуоресценції хлорофілу",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2014_st14": {
      authors: {
        en: "Kovyrova O.",
        ua: "Ковирьова О.В.",
      },
      title: {
        en: "Methods of chlorophyll fluorescence induction curves processing",
        ua: "Методи обробки вимірів кривих індукції флуоресценції хлорофілу",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2014_st15": {
      authors: {
        en: "Skachko D.",
        ua: "Скачко Д.А.",
      },
      title: {
        en: "Information technology in decision making based on classification and indoor movement analysis",
        ua: "Інформаційна технологія прийняття рішень на основі класифікації та аналізу переміщень всередині просторових об'єктів",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2014_st16": {
      authors: {
        en: "Timashov Ye.",
        ua: "Тимашов Є.О.",
      },
      title: {
        en: "A simulation model with virtual reality interfaces simulator doctor in developing LDK",
        ua: "Імітаційні моделі з інтерфейсом віртуальної реальності при розробці тренажерів лікаря ЛДК",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2014_st17": {
      authors: {
        en: "Tishchenko A.",
        ua: "Тищенко А.В.",
      },
      title: {
        en: "Information technologies for electric discharge",
        ua: "Інформаційні технології для електророзрядних процесів",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2014_st18": {
      authors: {
        en: "Khodakovskyi M.",
        ua: "Ходаковський М.І.",
      },
      title: {
        en: "Research model of search of knowledge in molecular memory with using ontology and agent-based programs",
        ua: "Дослідження моделей пошуку знань в молекулярній пам'яті з використанням онтологій і агентних програм",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2014_st19": {
      authors: {
        en: "Barkalov A., Bazydlo G., Vizor Y., Matvienko A., Titarenko L.",
        ua: "Баркалов А.А., Базидло Г., Візор Я.Є., Матвієнко А.В., Титаренко Л.А.",
      },
      title: {
        en: "Implementing circuit of control unit with two sources of codes",
        ua: "Реалізація схеми пристрою керування з вдома джерелами кодів",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2014_st20": {
      authors: {
        en: "Pisarenko J., Melkumyan E.",
        ua: "Писаренко Ю.В., Мелкумян Є.Ю.",
      },
      title: {
        en: "Some components of intellectual system Control_TEP",
        ua: "Деякі компоненти інтелектуальної системи КЕРУВАННЯ_ТЕП",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
  },
  2013: {
    "2013_zmist": {
      authors: {
        en: "",
        ua: "",
      },
      title: {
        en: "Table of contents",
        ua: "Зміст",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2013_st1": {
      authors: {
        en: "Semotyuk M.",
        ua: "Семотюк М.В.",
      },
      title: {
        en: "Analytical method for factoring composite numbers",
        ua: "Про аналітичний метод факторизації складених чисел",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2013_st2": {
      authors: {
        en: "Chebotarev A.",
        ua: "Чеботарьов А.М.",
      },
      title: {
        en: "Transformation of restrictions to the behavior of operational part of a reactive algorithm into the restrictions to the behavior of its control part",
        ua: "Перетворення обмежень на поведінку операційної частини реактивного алгоритму в обмеження на поведінку його керуючої частини",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2013_st3": {
      authors: {
        en: "Vakal L.",
        ua: "Вакал Л.П.",
      },
      title: {
        en: "Genetic algorithms for Chebyshev approximation",
        ua: "Генетичні алгоритми для чебишовської апроксимації",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2013_st4": {
      authors: {
        en: "Zosimov V., Bulgakova O., Tyshchenko A.",
        ua: "Зосімов В.В., Булгакова А.С., Тищенко А.В.",
      },
      title: {
        en: "Ways and methods of increasing complex calculations performance",
        ua: "Шляхи і методи підвищення швидкодії складних розрахунків",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2013_st5": {
      authors: {
        en: "Varava I.",
        ua: "Варава І.А.",
      },
      title: {
        en: "Organization of complex calculations in the field of interbranch research",
        ua: "Організація складних розрахунків в області міжгалузевих досліджень",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2013_st6": {
      authors: {
        en: "Voronenko A., Romanov V.",
        ua: "Вороненко О.В., Романов В.О.",
      },
      title: {
        en: "Choice of the uniform tools of data digital processing based on CORTEX core for biosensor devices",
        ua: "Вибір уніфікованих засобів процесорної обробки даних на основі Cortex-ядра для біосенсорних приладів",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2013_st7": {
      authors: {
        en: "Kovyrova O.",
        ua: "Ковирьова О.В.",
      },
      title: {
        en: "Review of the devices for measuring chlorophyll fluorescence induction",
        ua: "Огляд портативних приладів для вимірювання індукції флуоресценції хлорофілу",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2013_st8": {
      authors: {
        en: "Hrusha V.",
        ua: "Груша В.М.",
      },
      title: {
        en: "Application of wireless sensors network in researches of chlorophyll fluorescence induction method",
        ua: "Використання бездротової сенсорної мережі у дослідженнях методу індукції флуоресценції хлорофілу",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2013_st9": {
      authors: {
        en: "Palagin A., Petrenko N., Slabkovska M.",
        ua: "Палагін О.В., Петренко М.Г., Слабковська М.П.",
      },
      title: {
        en: "About one approach to the development of the hardware support of linguistic analysis",
        ua: "Про один підхід до розробки апаратних засобів підтримки лінгвістичного аналізу",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2013_st10": {
      authors: {
        en: "Brayko Yu., Imamutdinova R.",
        ua: "Брайко Ю.О., Імамутдінова Р.Г.",
      },
      title: {
        en: "Basic system requirements to smart portable devices and methodology of their designing",
        ua: "Основні системні вимоги до інтелектуальних приладів, методологія їх проектування",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2013_st11": {
      authors: {
        en: "Bekh A., Chernetsky V.",
        ua: "Бех О.Д., Чернецький В.В.",
      },
      title: {
        en: "Problem solution of noninvasive measuring of blood stream pressure on vessels walls",
        ua: "Рішення проблеми неінвазивного вимірювання тиску потоку крові на стінки судин",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2013_st12": {
      authors: {
        en: "Boyun V., Sabelnikov P., Sabelnikov Yu.",
        ua: "Боюн В.П., Сабельніков П.Ю., Сабельніков Ю.А.",
      },
      title: {
        en: "Approximation of closed contour image by polygon",
        ua: "Апроксимація замкненого контурного зображення багатокутником",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2013_st13": {
      authors: {
        en: "Artemenko D., Kolesnik Yu., Fedak V.",
        ua: "Артеменко Д.М., Колесник Ю.С., Федак В.С.",
      },
      title: {
        en: "Working out of technique of diagnostics of a condition of plants",
        ua: "Розробка методики діагностики стану рослин",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2013_st14": {
      authors: {
        en: "Kucherov O., Sarakhan Ye.",
        ua: "Кучеров О.П., Сарахан Є.В.",
      },
      title: {
        en: "Optimization of control of processes of planning perennial gardens based on economic and mathematical models",
        ua: "Оптимізація управління процесами планування багаторічних насаджень на основі економіко-математичних моделей",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2013_st15": {
      authors: {
        en: "Timashov Ye.",
        ua: "Тімашов Є.А.",
      },
      title: {
        en: "Computer systems analysis of therapeutic and diagnostic complex design",
        ua: "Системний аналіз комп'ютерних лікувально-діагностичних комплексів и їх проектування",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2013_st16": {
      authors: {
        en: "Pronenko M., Samolyuk T.",
        ua: "Проненко М.І., Самолюк Т.А.",
      },
      title: {
        en: "The integrated program medium of data processing of hydroacoustic systems",
        ua: "Інтегроване програмне середовище обробки даних (ІПСОД) гідроакустичних систем",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2013_st17": {
      authors: {
        en: "Pletinka O., Brovarska N., Potapenko A.",
        ua: "Плетінка О.В., Броварська Н.Й, Потапенко А.Ф.",
      },
      title: {
        en: "The problem of adaptation policy environment for virtual simulation of scientific experiment",
        ua: "До питання адаптації програмних середовищ для віртуального моделювання наукового експерименту",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2013_st18": {
      authors: {
        en: "Shevchuk B., Zadiraka V., Frayer S.",
        ua: "Шевчук Б.М., Задірака В.К., Фраєр С.В.",
      },
      title: {
        en: "Algorithmic basic increase information efficiency of data transmission in sensor networks",
        ua: "Алгоритмічні основи підвищення інформаційної ефективності передачі даних в сенсорних мережах",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2013_st19": {
      authors: {
        en: "Golovin A.",
        ua: "Головін А.М.",
      },
      title: {
        en: "About some features of wireless smart camera network organization",
        ua: "Про деякі особливості організації безпровідних мереж інтелектуальних відеокамер",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
  },
  2012: {
    "2012_zmist": {
      authors: {
        en: "",
        ua: "",
      },
      title: {
        en: "Table of contents",
        ua: "Зміст",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2012_st1": {
      authors: {
        en: "Palagin A.V., Petrenko N.G., Gorin F.N.",
        ua: "Палагін О.В., Петренко М.Г., Горін Ф.М.",
      },
      title: {
        en: "Ontology-oriented methodological scientific experiments",
        ua: "Онтолого-орієнтована методика проведення наукового експерименту",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2012_st2": {
      authors: {
        en: "Zinchenko S.",
        ua: "Зінченко С.В.",
      },
      title: {
        en: "Research of performance of knowledge oriented intellectual soft real-time systems",
        ua: "Дослідження характеристик знання орієнтованих інтелектуальних систем м'якого реального часу",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2012_st3": {
      authors: {
        en: "Khodakovskyi M.I.",
        ua: "Ходаковський М.І.",
      },
      title: {
        en: "Investigation of sinaptical systems of molecular memory and its microprocessor properties for training",
        ua: "Дослідження синаптичних систем молекулярної пам'яті та їхніх мікропроцесорних властивостей при навчанні",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2012_st4": {
      authors: {
        en: "Barkalov A.A., Vizor Y.E., Matvienko A.V.",
        ua: "Баркалов А.А., Візор Я.Є., Матвієнко А.В.",
      },
      title: {
        en: "The method of encoding of fragment sets of variables",
        ua: "Метод кодування наборів фрагменту змінних",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2012_st5": {
      authors: {
        en: "Semotiuk M., Chichirin E., Sosnenko E.",
        ua: "Семотюк М.В., Чичирин Є.Н., Сосненко Є.П.",
      },
      title: {
        en: "Organization of layered modeling for verification of digital projects",
        ua: "Організація багаторівневого моделювання для верифікації цифрових проектів",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2012_st6": {
      authors: {
        en: "Khramov V.V., Sudakov O.O., Kononov M.V., Ustimenko V.M., Budnyk M.M.",
        ua: "Храмов В.В., Судаков О.О., Кононов М.В., Устименко В.М., Будник М.М.",
      },
      title: {
        en: "Modeling forest fires on grid-clusters",
        ua: "Моделювання лісових пожеж на Grid-кластерах",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2012_st7": {
      authors: {
        en: "Najafian M.A., Vasyliev V.Ye., Chaykovskyy I.A.",
        ua: "Наджафіан М.А., Васильєв В.Є., Чайковський І.А.",
      },
      title: {
        en: "Preliminary processing of magnetocardiograms: requirements, structure, realization",
        ua: "Первинна обробка магнітокардіограм: вимоги, структура, реалізація",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2012_st8": {
      authors: {
        en: "Bekh A., Chernetsky V.",
        ua: "Бех А.Д., Чернецький В.В.",
      },
      title: {
        en: "The measuring of the dynamic parameters of the cardiovascular system",
        ua: "Вимірювання динамічних параметрів серцево-судинної системи",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2012_st9": {
      authors: {
        en: "Timashov O.",
        ua: "Тимашов Є.О.",
      },
      title: {
        en: "Information technology to create test systems of mobile medical diagnostic systems",
        ua: "Інформаційна технологія створення тестових систем мобільних лікувально-діагностичних комплексів",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2012_st10": {
      authors: {
        en: "Lukash S.I., Vakal L.P.",
        ua: "Лукаш С.І., Вакал Л.П.",
      },
      title: {
        en: "Development of the measurement technique of marker gases in a breath air",
        ua: "Розробка методики вимірів маркерних газів у повітрі дихання",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2012_st11": {
      authors: {
        en: "Romanov V.A., Galelyuka I.B.",
        ua: "Романов В.О., Галелюка І.Б.",
      },
      title: {
        en: "Computer device for express-diagnostics of plant state: results of international project of preparing for serial production",
        ua: "Комп’ютерний прилад для експрес-діагностики стану рослин: результати міжнародного проекту по підготовці до серійного випуску",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2012_st12": {
      authors: {
        en: "Artemenko D., Fedak V.",
        ua: "Артеменко Д.М., Федак В.С.",
      },
      title: {
        en: "Condition of the plants and its diagnosis",
        ua: "Стан рослин та його діагностика",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2012_st13": {
      authors: {
        en: "Sarakhan Ye.",
        ua: "Сарахан Є.В.",
      },
      title: {
        en: "Interdisciplinary researches and the ways of system integration of information technologies in plant growing industry",
        ua: "Міждисциплінарні дослідження та шляхи системної інтеграції інформаційних технологій у рослинницьку галузь",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2012_st14": {
      authors: {
        en: "Hrusha V., Kovyrova O.",
        ua: "Груша В.М., Ковирьова О.В.",
      },
      title: {
        en: "Research of fluorometer 'Floratest' sensitivity to stress factors on state of plants",
        ua: "Дослідження чутливості флуориметра 'Флоратест' до дії стресових факторів на стан рослин",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2012_st15": {
      authors: {
        en: "Boyun V., Bagatskij O.",
        ua: "Боюн В.П., Багацький О.В.",
      },
      title: {
        en: "Analysis of regulations on the quality of public utilities",
        ua: "Аналіз нормативних документів з якості комунальних послуг",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2012_st16": {
      authors: {
        en: "Zinchenko V., Pletinka O., Brovarska N., Potapenko A.",
        ua: "Зінченко В.П., Плетінка О.В., Броварська Н.Й., Потапенко А.Ф.",
      },
      title: {
        en: "Simulation of the parameters of communication systems with the use of technology of virtual environment",
        ua: "Моделювання параметрів систем зв’язку з використанням технології віртуального оточення",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2012_st17": {
      authors: {
        en: "Gayvoronska G., Sakharova S., Bondarenko A.",
        ua: "Гайворонська Г.С., Сахарова С.В., Бондаренко А.А.",
      },
      title: {
        en: "Software implementation of projected orthogonal access network",
        ua: "Програмна реалізація проектованої ортогональної мережі доступу",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2012_st18": {
      authors: {
        en: "Solomitsky M.",
        ua: "Соломицький М.Ю.",
      },
      title: {
        en: "Decision of choice problem on the set of mathematical models of events' streams",
        ua: "Вирішення задачі вибору на множині математичних моделей потоків подій",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2012_st19": {
      authors: {
        en: "Lebyedyeva T., Ozerov M., Shpylovyy P.",
        ua: "Лебедєва Т.С., Озеров М.В., Шпильовий П.Б.",
      },
      title: {
        en: "Modeling and investigation of thin-film structures interface",
        ua: "Моделювання і дослідження перехідного шару тонкоплівкових структур",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
  },
  2011: {
    "2011_zmist": {
      authors: {
        en: "",
        ua: "",
      },
      title: {
        en: "Table of contents",
        ua: "Зміст",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2011_st1": {
      authors: {
        en: "Palagin A.V., Petrenko N.G., Malakhov K.S.",
        ua: "Палагін О.В., Петренко М.Г., Малахов К.С.",
      },
      title: {
        en: "Technique for designing a domain ontology",
        ua: "Методика проектування онтології предметної області",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2011_st2": {
      authors: {
        en: "Chebotarev A.N., Bogachenko A.K.",
        ua: "Чеботарьов А.М., Богаченко А.К.",
      },
      title: {
        en: "Synthesis of an FSM specified in the language L* by passing to the specification in the language L",
        ua: "Синтез автомату, специфікованого на мові L*, шляхом переходу до специфікації на мові L",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2011_st3": {
      authors: {
        en: "Barkalov A.A., Matvienko A.V., Tsololo S.A.",
        ua: "Баркалов А.А., Матвієнко А.В., Цололо С.А.",
      },
      title: {
        en: "Optimization of logic circuit of Moore FSM on FPGA",
        ua: "Оптимізація логічної схеми автомата мура на FPGA",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2011_st4": {
      authors: {
        en: "Khodakovskyi M.I.",
        ua: "Ходаковський Н.І.",
      },
      title: {
        en: "Investigation of processes of molecular coding of neural networks for training",
        ua: "Дослідження процесів молекулярного кодування нейронних мереж при навчанні",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2011_st5": {
      authors: {
        en: "Semotiuk M.V.",
        ua: "Семотюк М.В.",
      },
      title: {
        en: "The precise method for factoring composite numbers",
        ua: "Про точний метод факторизації складених чисел",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2011_st6": {
      authors: {
        en: "Velychko V.Yu.",
        ua: "Величко В.Ю.",
      },
      title: {
        en: "An algorithm of growing pyramidal networks building in parallel computing environment",
        ua: "Алгоритм побудови зростаючих пірамідальних мереж у паралельному обчислювальному середовищі",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2011_st7": {
      authors: {
        en: "Bovsunivsky V.I.",
        ua: "Бовсунівський В.І.",
      },
      title: {
        en: "The technology of rapid filtration and compression of video data in real-time",
        ua: "Технологія оперативної фільтрації і стиску відеоданих у реальному часі",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2011_st8": {
      authors: {
        en: "Lavrentyev V.N.",
        ua: "Лаврентьєв В.М.",
      },
      title: {
        en: "The compression algorithm for images",
        ua: "Алгоритм стиску зображень",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2011_st9": {
      authors: {
        en: "Vakal L.P.",
        ua: "Вакал Л.П.",
      },
      title: {
        en: "Using of Chebyshev approximation for solving of integral equations",
        ua: "Застосування чебишовської апроксимації при розв'язанні інтегральних рівнянь",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2011_st10": {
      authors: {
        en: "Romanov V.A., Artemenko D.M., Brayko Ya.O., Galelyuka I.B., Imamutdinova R.G., Fedak V.S.",
        ua: "Романов В.О., Артеменко Д.М., Брайко Ю.О., Галелюка І.Б., Імамутдінова Р.Г., Федак В.С.",
      },
      title: {
        en: "Portable devices of 'Floratest' family: preparation for serial production",
        ua: "Сімейство портативних приладів 'Флоратест': підготовка до серійного виробництва",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2011_st11": {
      authors: {
        en: "Sarakhan Ye.",
        ua: "Сарахан Є.В.",
      },
      title: {
        en: "The features of applications of portable biosensor devices of 'Floratest' family",
        ua: "Особливості практичного застосування портативних біосенсорних приладів сімейства 'Флоратест'",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2011_st12": {
      authors: {
        en: "Kondratov V.T., Milchenko N.V.",
        ua: "Кондратов В.Т., Мільченко А.В.",
      },
      title: {
        en: "Measurement of superficial concentration of the chlorophyll in leaves of plants",
        ua: "Вимірювання поверхневої концентрації хлорофілу в листках рослин",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2011_st13": {
      authors: {
        en: "Kurzantseva L.I.",
        ua: "Курзанцева Л.І.",
      },
      title: {
        en: "The model of adaptive interface for training purposes",
        ua: "Макет адаптивного інтерфейсу для учбово-тренувальних цілей",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2011_st14": {
      authors: {
        en: "Lukash S.I.",
        ua: "Лукаш С.І.",
      },
      title: {
        en: "Розробка методики вимірювання СО2 в повітрі, що видихається",
        ua: "Development of the technique of measurement СО2 in exhaled air",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2011_st15": {
      authors: {
        en: "Zinchenko V.P., Zinchenko S.V.",
        ua: "Зінченко В.П., Зінченко С.В.",
      },
      title: {
        en: "Architecture and organization remote access information system to microsatellite",
        ua: "Архітектура і організація системи віддаленого доступу до інформації мікросупутника",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2011_st16": {
      authors: {
        en: "Mischenko A.N.",
        ua: "Міщенко А.М.",
      },
      title: {
        en: "Algorithms of changeover of containers-keys at stream enciphering by a method of indirect enciphering",
        ua: "Алгоритми заміни контейнерів-ключів при потоковому шифруванні методом непрямого шифрування",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2011_st17": {
      authors: {
        en: "Shevchuk B.M.",
        ua: "Шевчук Б.М.",
      },
      title: {
        en: "Operative formation and committing to a compact, stable and crypto-correcting packages of information in radio networks",
        ua: "Оперативне формування і передавання компактних, крипто-стійких та завадостійких пакетів інформації у радіомережах",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2011_st18": {
      authors: {
        en: "Kriachok A., Shevchuk A.V.",
        ua: "Крячок А.С., Шевчук А.В.",
      },
      title: {
        en: "Application of DLP-systems for data protection in corporation network",
        ua: "Про застосування DLP-систем для захисту даних в корпоративних системах",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
  },
  2010: {
    "2010_zmist": {
      authors: {
        en: "",
        ua: "",
      },
      title: {
        en: "Table of contents",
        ua: "Зміст",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2010_st1": {
      authors: {
        en: "Bruhovich E.I.",
        ua: "Брюхович Е.І.",
      },
      title: {
        en: "Aggregate product of scientific foresight  of ultimate developed forms of computer science. Part I",
        ua: "Сукупний продукт наукового передбачення розвинених форм обчислювальної техніки. Ч.I",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2010_st2": {
      authors: {
        en: "Bruhovich E.I.",
        ua: "Брюхович Е.І.",
      },
      title: {
        en: "Aggregate product of scientific foresight of ultimate developed forms of computer science. Part II",
        ua: "Сукупний продукт наукового передбачення розвинених форм обчислювальної техніки. Ч.II",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2010_st3": {
      authors: {
        en: "Zakorchenyi O.V., Budnyk M.M.",
        ua: "Закорчений О.В., Будник М.М.",
      },
      title: {
        en: "General solution of classification problem at probability space",
        ua: "Загальний розв'язок задачі класифікації у просторі ймовірностей",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2010_st4": {
      authors: {
        en: "Khodakovskyi M.I.",
        ua: "Ходаковський М.І.",
      },
      title: {
        en: "Research of the knowledge-systems in subsystems of the natural intelligence",
        ua: "Дослідження систем, заснованих на знаннях в підсистемах природного інтелекту",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2010_st5": {
      authors: {
        en: "Vakal L.",
        ua: "Вакал Л.П.",
      },
      title: {
        en: "Solving of boundary problems using software for chebyshev approximations",
        ua: "Розв'язання крайових задач з використанням програмних засобів чебишовських наближень",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2010_st6": {
      authors: {
        en: "Romanov V.A., Galelyuka I.B., Klochan P.S.",
        ua: "Романов В.О., Галелюка І.Б., Клочан П.С.",
      },
      title: {
        en: "Technologies for person authentication by using biometric characteristics",
        ua: "Технології аутентифікації особи за біометричними характеристиками",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2010_st7": {
      authors: {
        en: "Lukash S.I.",
        ua: "Лукаш С.І.",
      },
      title: {
        en: "Problems of diagnostics of some diseases on exhaled air",
        ua: "Проблеми діагностики деяких захворювань по видихуваному повітрі",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2010_st8": {
      authors: {
        en: "Kovyriova O.V.",
        ua: "Ковирьова О.В.",
      },
      title: {
        en: "Models of photosynthesis and computer evaluation of plants' state",
        ua: "Моделі фотосинтезу та комп'ютерна оцінка стану рослин",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2010_st9": {
      authors: {
        en: "Sarakhan Ye.V.",
        ua: "Сарахан Є.В.",
      },
      title: {
        en: "Information technologies in precision agriculture",
        ua: "Інформаційні технології в прецизионном землеробстві",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2010_st10": {
      authors: {
        en: "Artemenko D.M., Kitaev O.I., Fedak V.S.",
        ua: "Артеменко Д.М., Китаєв О.І., Федак В.С.",
      },
      title: {
        en: "Portable PAM chlorophyll-fluoremeter with measuring of thermoinduction characteristics",
        ua: "Портативний PAM хлорофіл-флуорометр з вимірюванням показників термоіндукції",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2010_st11": {
      authors: {
        en: "Hrusha V.M.",
        ua: "Груша В.М.",
      },
      title: {
        en: "Modelling and using differential drivers for high speed ADCs",
        ua: "Моделювання і застосування диференціальних драйверів для швидкодіючих АЦП",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2010_st12": {
      authors: {
        en: "Velychko V.Ju., Palagin A.V.",
        ua: "Величко В.Ю., Палагін О.В.",
      },
      title: {
        en: "Methods of effectiveness increase of application of growing pyramidal networks",
        ua: "Методи підвищення ефективності застосування зростаючих пірамідальних мереж",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2010_st13": {
      authors: {
        en: "Alishov N.I., Alishov A.N.,Lutskiy M.G., Mischenko A.N., Sapunova N.A.",
        ua: "Алишов Н.І., Алишов А.Н., Луцкий М.Г., Міщенко А.Н., Сапунова Н.А.",
      },
      title: {
        en: "The architecture of hardware of implementation of methods of indirect steganography",
        ua: "Архітектура апаратних засобів реалізації методів непрямої стеганографії",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2010_st14": {
      authors: {
        en: "Bezverbnyy I.",
        ua: "Безвербний І.А.",
      },
      title: {
        en: "Numerical and analytical methods of a signaling tone frequency-phase analysis of the low-frequency acoustic measuring system",
        ua: "Чисельно-аналітичні методи частотно-фазового аналізу тональних сигналів у низькочастотній акустичній вимірювальній системі",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2010_st15": {
      authors: {
        en: "Chevchuk B.M.",
        ua: "Шевчук Б.М.",
      },
      title: {
        en: "Processing, coding and transferring data by user's systems of information-effective radionetworks",
        ua: "Оброблення, кодування та передавання даних засобами абонентських систем інформаційно-ефективних радіомереж",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2010_st16": {
      authors: {
        en: "Varava І.A.",
        ua: "Варава І.А.",
      },
      title: {
        en: "The elements of CAD for unmanned aircraft based on CFD-software",
        ua: "Елементи САПР безпілотних літальних апаратів на базі CFD-пакетів",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2010_st17": {
      authors: {
        en: "Pisarenko V.G., Pisarenko J.V., Melkumyan Ye.Ju., Koval A.S.",
        ua: "Писаренко В.Г., Писаренко Ю.В., Мелкумян Е.Ю., Коваль А.С.",
      },
      title: {
        en: "The components and basic concept of intellectual mobile robots for mountain rescue works",
        ua: "Концепція та базові компоненти інтелектуального мобільного робота для гірничорятувальних робіт",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2010_st18": {
      authors: {
        en: "Panasyuk Ju.Ya., Kulyas A.I., Gavrilyuk O.N., Prokopchuk V.V., Kravchenko E.V.",
        ua: "Панасюк Ю.Я., Куляс А.И., Гаврилюк О.Н., Прокопчук В.В., Кравченко Е.В.",
      },
      title: {
        en: "The method for rapid assessment of caving volume by robot after accident in mine",
        ua: "Метод оперативної оцінки роботом обсягу порід, завалених через аварії в шахті",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
  },
  2009: {
    "2009_zmist": {
      authors: {
        en: "",
        ua: "",
      },
      title: {
        en: "Table of contents",
        ua: "Зміст",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2009_st1": {
      authors: {
        en: "Palagin A.V., Cemotjuk M.V., Chichirin E.N., Sosnenko K.P.",
        ua: "Палагін О.В., Семотюк М.В., Чічірін Е.Н., Сосненко К.П.",
      },
      title: {
        en: "Acoustic Commander – integrated operational environment for measurement and calculating acoustic parameters",
        ua: "Acoustic Commander – інтегроване операційне середовище для вимірювання і розрахунку акустичних параметрів",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2009_st2": {
      authors: {
        en: "Pisarenko V., Boyun V., Pisarenko J.",
        ua: "Писаренко В.Г., Боюн В.П., Писаренко Ю.В.",
      },
      title: {
        en: "Close to the real time technology for fast identification and neutralization of dangerous techno-ecological accidences",
        ua: "Інформаційна технологія оперативного визначення і нейтралізації шкідливих техно-екологічних випадків (ТЕВ), близька до реального часу",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2009_st3": {
      authors: {
        en: "Zaslavskii V., Yermolenko R., Sakhno N., Kadenko I.",
        ua: "Заславський В.А., Єрмоленко Р.В., Сахно Н.В., Каденко І.М.",
      },
      title: {
        en: "Software for NPP steam generators safe operation",
        ua: "Програмне забезпечення для управління безпечною експлуатацією парогенераторів АЕС",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2009_st4": {
      authors: {
        en: "Kalenchuk-Porkhanova A., Fesun T.",
        ua: "Каленчук-Порханова А.А., Фесун Т.М.",
      },
      title: {
        en: "Linear interpolation of functions of many-variables",
        ua: "Лінійне інтерполювання функцій багатьох змінних",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2009_st5": {
      authors: {
        en: "Vakal L.",
        ua: "Вакал Л.П.",
      },
      title: {
        en: "Software for experimental data approximation by empiric formula",
        ua: "Програмні засоби для наближення дослідних даних емпіричними формулами",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2009_st6": {
      authors: {
        en: "Barkalov A.A., Matvienko A.V., Tsololo S.A.",
        ua: "Баркалов А.А., Матвієнко А.В., Цололо С.А.",
      },
      title: {
        en: "Optimization of Moore FSM logic circuit with homogeneous CPLD",
        ua: "Оптимізація схеми автомата Мура на однорідних ПЛІС",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2009_st7": {
      authors: {
        en: "Gupal A.M., Ostapko O.I., Grachova T.J., Vorobyov O.S.",
        ua: "Гупал А.М., Остапко О.І., Грачова Т.Я., Воробйов О.С.",
      },
      title: {
        en: "Applying of computer applications in tooth disease data processing and modeling",
        ua: "Комп'ютерні засоби в моделюванні процесів стоматологічної захворюваності",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2009_st8": {
      authors: {
        en: "Opanasenko V., Lisovy A.",
        ua: "Опанасенко В.М., Лісовий О.М.",
      },
      title: {
        en: "The designing process formalization of computing devices and systems on PLD base",
        ua: "Формалізація процесу проектування обчислювальних пристроїв та систем на базі ПЛІС",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2009_st9": {
      authors: {
        en: "Romanov V.O., Galelyuka I.B., Hrusha V.M., Chernega P.P.",
        ua: "Романов В.О., Галелюка І.Б., Груша В.М., Чернега П.П.",
      },
      title: {
        en: "Distributed system of data acquisition and processing on the base of smart portable devices",
        ua: "Розподілена система збору і обробки інформації на базі інтелектуальних портативних приладів",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2009_st10": {
      authors: {
        en: "Bruhovich E.I.",
        ua: "Брюхович Е.И.",
      },
      title: {
        en: "Corporate network of corporate networks of Computer Sapiens",
        ua: "Корпоративна мережа корпоративних мереж Computer Sapiens",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2009_st11": {
      authors: {
        en: "Chevchuk B.",
        ua: "Шевчук Б.М.",
      },
      title: {
        en: "Models and methods of processing, coding and information transfer for building information-effective computer networks",
        ua: "Моделі та методи обробки, кодування і передачі інформації для побудови інформаційно-ефективних комп'ютерних мереж",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2009_st12": {
      authors: {
        en: "Rezaei V.",
        ua: "Резаі В.",
      },
      title: {
        en: "Establishment of wireless networks for monitoring industrial production",
        ua: "Створення бездротових мереж моніторингу промислового виробництва",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2009_st13": {
      authors: {
        en: "Zelentsova J.",
        ua: "Зеленцова Ж.Ю.",
      },
      title: {
        en: "Застосування мультиагентної технологія для оптимізації фінансових потоків в режимі реального часу з 'stop-компетенціями'",
        ua: "Application of technology for optimization of multi financial flows in real time with the 'stop-competencies'",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2009_st14": {
      authors: {
        en: "Alishov N.I., Marchenko V.A., Orugeva S.G.",
        ua: "Алишов Н.І., Марченко В.А., Оруджева С.Г.",
      },
      title: {
        en: "Indirect steganography as the new method for the protection of computer data",
        ua: "Непряма стеганографія як новий спосіб захисту комп'ютерних даних",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2009_st15": {
      authors: {
        en: "Zinchenko V.P., Khodakovskyi M.I., Zinchenko S.V., Tatulashvili T.I.",
        ua: "Зінченко В.П., Ходаковський М.І., Зінченко С.В., Татулашвілі Т.І.",
      },
      title: {
        en: "The ontological approuch for designing, modeling the automated learning systems",
        ua: "Онтологічний підхід до проектування автоматизованих навчаючих систем",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2009_st16": {
      authors: {
        en: "Kurgaev A.F., Savchenko I.V.",
        ua: "Кургаєв О.П., Савченко І.В.",
      },
      title: {
        en: "Search methods researching in a dictionary of terms",
        ua: "Дослідження методів пошуку в словнику термінів",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2009_st17": {
      authors: {
        en: "Yelisyeyeva E.V.",
        ua: "Єлісєєва Е.В.",
      },
      title: {
        en: "Structure and functions of a control packet of intellectual system of memory",
        ua: "Структура і функції керуючого пакету інтелектуальної системи пам'яті",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2009_st18": {
      authors: {
        en: "Kondratov V.T.",
        ua: "Кондратов В.Т.",
      },
      title: {
        en: "Problems of the theory of metrological reliability and ways of their solution",
        ua: "Проблеми теорії метрологічної надійності і шляхи їх вирішення",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2009_st19": {
      authors: {
        en: "Artemenko D., Kitaev O., Klochan P., Kolesnik Yu., Fedak V.",
        ua: "Артеменко Д.М., Китаєв О.І., Клочан П.С., Колесник Ю.С., Федак В.С",
      },
      title: {
        en: "The optoelectronic device for estimating of plant state",
        ua: "Оптоелектронний пристрій визначення стану рослин",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2009_st20": {
      authors: {
        en: "Voitovych I.D., Lukash S.I., Panov E.V., Genkina E.A., Malevaniy S.M., Gluschak T.S.",
        ua: "Войтович І.Д., Лукаш С.І., Панов Є.В., Генкіна Е.А., Мальований С.М., Глущак Т.С.",
      },
      title: {
        en: "Design of the intellectual device 'electronic nose'",
        ua: "Розробка вітчизняного інтелектуального приладу 'електронний ніс'",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
  },
  2008: {
    "2008_zmist": {
      authors: {
        en: "",
        ua: "",
      },
      title: {
        en: "Table of contents",
        ua: "Зміст",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2008_st1": {
      authors: {
        en: "Bruhovich E.I.",
        ua: "Брюхович Е.І.",
      },
      title: {
        en: "About conception of abiopsychology and individual abiopsychology of Computer Sapiens",
        ua: "Про поняття абіопсихології і про індивідуальну абіопсихологію Computer Sapiens",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2008_st2": {
      authors: {
        en: "Kondratov V.T.",
        ua: "Кондратов В.Т.",
      },
      title: {
        en: "Issue of work with metrological numbers – the issue, which will integrate metrology, computer science and informatics",
        ua: "Проблема роботи з метрологічними числами - проблема, яка об'єднає метрологію, інформатику і обчислювальну техніку",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2008_st3": {
      authors: {
        en: "Khodakovskyi M.I.",
        ua: "Ходаковський Н.І.",
      },
      title: {
        en: "Research of informational nanotechnologies of information processing for bilding of systems on base of the knowledges",
        ua: "Дослідження інформаційних нанотехнологій обробки інформації для побудови систем на основі знань",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2008_st4": {
      authors: {
        en: "Kalenchuk-Porkhanova A., Vakal L.",
        ua: "Каленчук-Порханова А.А., Вакал Л.П.",
      },
      title: {
        en: "Function approximations package",
        ua: "Пакет програм апроксимації функцій",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2008_st5": {
      authors: {
        en: "Yelisyeyeva E.V., Yakovlev Yu.S.",
        ua: "Єлісєєва Е.В., Яковлєв Ю.С.",
      },
      title: {
        en: "The concept and principles of construction of formats and set of commands of hypothetical PIM-system",
        ua: "Концепція і принципи побудови форматів і набору команд гіпотетичної РІМ-системи",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2008_st6": {
      authors: {
        en: "Gupal A.M., Lanovenko I.I., Grachova T.J., Vorobyov O.S.",
        ua: "Гупал А.М., Лановенко І.І., Грачова Т.Я., Воробйов А.С.",
      },
      title: {
        en: "The analysis of correlation and regression in research genesis of hemic hypoxia",
        ua: "Кореляційний і регресійний аналіз при дослідженні генезу гемічної гіпоксії",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2008_st7": {
      authors: {
        en: "Gridina N.Ya., Tarasov A.L.",
        ua: "Грідіна Н.Я., Тарасов А.Л.",
      },
      title: {
        en: "Differential diagnosis of inflammation and oncological diseases",
        ua: "Диференціальна діагностика запальних і онкологічних захворювань",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2008_st8": {
      authors: {
        en: "Khashan T., Pisarenko J.",
        ua: "Хашан Т.С., Писаренко Ю.В.",
      },
      title: {
        en: "Speech and special signals direction finding for the technical hearing system in the intellectual robotics",
        ua: "Пеленгація мовних і спеціальних сигналів системою технічного слуху в інтелектуальній робототехніці",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2008_st9": {
      authors: {
        en: "Opanasenko V., Lisovyi A., Soroka E.",
        ua: "Опанасенко В.М., Лісовий О.Н., Сорока Е.В.",
      },
      title: {
        en: "The designing, modeling and physical verification of digital devises on PLD base",
        ua: "Проектирование и физическая верификация цифровых устройств на ПЛИС",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2008_st10": {
      authors: {
        en: "Barkalov A.A., Matvienko A.V., Tsololo S.A.",
        ua: "Баркалов А.А., Матвієнко А.В., Цололо С.А.",
      },
      title: {
        en: "Optimization of circuit of Moore FSM with using CPLD's features",
        ua: "Зменшення апаратурних затрат в схемі автомата Мура з використанням особливостей базису CPLD",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2008_st11": {
      authors: {
        en: "Bekh A.D., Chernetsky V.V.",
        ua: "Бех А.Д., Чернецький В.В.",
      },
      title: {
        en: "Hardware methods of the increase of the sensitivity of electromagnetic voltage amplifiers",
        ua: "Апаратні методи підвищення чутливості електромагнітних підсилювачів напруги",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2008_st12": {
      authors: {
        en: "Lukash S.I., Vojtovich I.D., Vetkina Z.V., Kolesnitski O.K.",
        ua: "Лукаш С.І., Войтович І.Д., Вєткіна З.В., Колесницький О.К.",
      },
      title: {
        en: "Features of activity of semiconducting sensor array in a system 'electronic nose'. Part II",
        ua: "Особливості роботи матричних напівпровідникових сенсорів в системі 'електронний ніс'. Ч. ІІ",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2008_st13": {
      authors: {
        en: "Kurzantseva L.I.",
        ua: "Курзанцева Л.І.",
      },
      title: {
        en: "About the adaptive intellectual interface 'The user – system of mass application'",
        ua: "Про адаптивний інтелектуальний інтерфейс 'Користувач - система масового застосування'",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2008_st14": {
      authors: {
        en: "Gladun A.",
        ua: "Гладун А.Я.",
      },
      title: {
        en: "Modeling of interaction of WEB-services and their performance analysis in the interactive environment of the Internet",
        ua: "Модель взаємодії WEB-сервісів та аналіз їх продуктивності в інтерактивному середовищі Інтернет",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2008_st15": {
      authors: {
        en: "Palagin A.V., Svitla S.Ju., Petrenko M.G., Velychko V.Ju.",
        ua: "Палагін О.В., Світла С.Ю., Петренко М.Г., Величко В.Ю.",
      },
      title: {
        en: "About one approach to analysis and understanding of the natural language objects",
        ua: "Про один підхід до аналізу та розуміння природномовних об'єктів",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2008_st16": {
      authors: {
        en: "Galelyuka I.",
        ua: "Галелюка І.Б.",
      },
      title: {
        en: "Information technologies for working out of methods of smart portable devices",
        ua: "Інформаційні технології в напрацюванні методичного забезпечення інтелектуальних портативних приладів",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2008_st17": {
      authors: {
        en: "Romanov V., Hrusha V., Artemenko D., Skrypnyk O., Vilk N.",
        ua: "Романов В.О., Груша В.М., Артеменко Д.М., Скрипник О.В., Вільк Н.М.",
      },
      title: {
        en: "Smart sensors: features and issues of development",
        ua: "Інтелектуальні сенсори: особливості та проблеми проектування",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2008_st18": {
      authors: {
        en: "Derkach A.V.",
        ua: "Деркач А.В.",
      },
      title: {
        en: "Application of ontotology for automation weight development of the complex technical object",
        ua: "Застосування онтології для автоматизації вагового проектування складних технічних об'єктів",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
  },
  2007: {
    "2007_zmist": {
      authors: {
        en: "",
        ua: "",
      },
      title: {
        en: "Table of contents",
        ua: "Зміст",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2007_st1": {
      authors: {
        en: "Bruhovich E.I.",
        ua: "Брюхович Є.І.",
      },
      title: {
        en: "Godel's theorem of widening the cognitive science resources and the Computer Science",
        ua: "Теорема Гьоделя в розширенні пізнавального ресурсу науки і Computer Science",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2007_st2": {
      authors: {
        en: "Palagin A.V., Petrenko N.G., Sevruk A.O.",
        ua: "Палагін О.В., Петренко М.Г., Севрук А.О.",
      },
      title: {
        en: "About one approach to formalized reprezentation ontology of the text document",
        ua: "Про один підхід до формалізованого представлення онтології текстового документа",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2007_st3": {
      authors: {
        en: "Jakovlev Ju.S.",
        ua: "Яковлєв Ю.С.",
      },
      title: {
        en: "About construction of ontology of the subject domain 'Reconfigurable PIM-systems'",
        ua: "Про побудову онтології предметної області 'Реконфігуровані PIM-системи'",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2007_st4": {
      authors: {
        en: "Pleskach V., Rogushina J.",
        ua: "Плескач В.Л., Рогушина Ю.В.",
      },
      title: {
        en: "Elements of ontological analysis in digital economy",
        ua: "Елементи онтологічного аналізу у цифровій економіці",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2007_st5": {
      authors: {
        en: "Khodakovskyi M.I.",
        ua: "Ходаковский М.І.",
      },
      title: {
        en: "Research of principles using of informational nanotechnologies at the building of ontology-driven learning systems",
        ua: "Дослідження принципів використання інформаційних нанотехнологій при створенні онтолого-керованих навчальних систем",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2007_st6": {
      authors: {
        en: "Barkalov A.A., Matvienko A.V., Tsololo S.A.",
        ua: "Баркалов А.А., Матвієнко А.В., Цололо С.А.",
      },
      title: {
        en: "Optimization of logic circuit of Moore FSM on CPLD",
        ua: "Оптимізація логічної схеми автомата Мура на CPLD",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2007_st7": {
      authors: {
        en: "Romanov V.O., Galelyukа I.B.",
        ua: "Романов В.О., Галелюка І.Б.",
      },
      title: {
        en: "High speed ADC testing and evaluation",
        ua: "Тестування та оцінювання параметрів швидкодіючих АЦП",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2007_st8": {
      authors: {
        en: "Brayko Y.О., Imamutdinova R.G.",
        ua: "Брайко Ю.О., Імамутдінова Р.Г.",
      },
      title: {
        en: "The designing of smart devices to ecologic and industrial monitoring systems",
        ua: "Проектування інтелектуальних приладів для систем екологічного і промислового моніторингу",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2007_st9": {
      authors: {
        en: "Pisarenko V., Varava I.",
        ua: "Писаренко В.Г., Варава І.А.",
      },
      title: {
        en: "Information models for heatphysics processes measuring of temperature's temporal dynamics of crystallized fusion of metal",
        ua: "Інформаційні моделі теплофізичних процесів при вимірюваннях часової динаміки температури розплаву металу, що кристалізується",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2007_st10": {
      authors: {
        en: "Zakorchenyy O.V., Minov Yu.D., Budnyk M.M.",
        ua: "Закорчений О.В., Мінов Ю.Д., Будник М.М.",
      },
      title: {
        en: "Selection of optimal design of input antennas of SQUID-magnetometer for measurement at unshielded environment",
        ua: "Вибір оптимальної конструкції вхідної антени СКВІД-магнітометра для вимірювань у неекранованому приміщенні",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2007_st11": {
      authors: {
        en: "Lukash S.I., Vojtovich I.D., Ahlers H.",
        ua: "Лукаш С.І., Войтович І.Д., Алерс Х.",
      },
      title: {
        en: "Features of activity of semiconducting sensor array in a system 'electronic nose'. Part 1",
        ua: "Особливості роботи матричних напівпровідникових сенсорів в системі 'електронний ніс'. Ч.1",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2007_st12": {
      authors: {
        en: "Zinchenko V.P., Zinchenko S.V., Gorin F.N., Chumakov V.G.",
        ua: "Зінченко В.П., Зінченко С.В., Горін Ф.М., Чумаков В.Г.",
      },
      title: {
        en: "Realization means for information technologies for aerodynamic tests",
        ua: "Засоби реалізації інформаційних технологій аеродинамічних випробувань",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2007_st13": {
      authors: {
        en: "Shevchuk B.M., Potapenko A.F., Chumakova N.F.",
        ua: "Шевчук Б.М., Потапенко А.Ф., Чумакова Н.Ф.",
      },
      title: {
        en: "Effective methods of the signals and images filtration and compaction in the problems tasks of identifying informational states of the long-term monitoring objects",
        ua: "Ефективні методи фільтрації-стиску сигналів і зображень у задачах визначення інформаційних станів об'єктів тривалого моніторингу",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2007_st14": {
      authors: {
        en: "Kurzantseva L.I.",
        ua: "Курзанцева Л.І.",
      },
      title: {
        en: "About construction of the intellectual interface of computer system with properties of adaptation",
        ua: "Про побудову інтелектуального інтерфейсу комп'ютерної системи з властивостями адаптації",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2007_st15": {
      authors: {
        en: "Gromovskiy A.V.",
        ua: "Громовський О.В.",
      },
      title: {
        en: "Routing algorithm EXZRP in convergent networks of the next generation",
        ua: "Алгоритм маршрутизації EXZRP в інтегрованих мережах нового покоління",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2007_st16": {
      authors: {
        en: "Oksyuk O.A.",
        ua: "Оксюк О.А.",
      },
      title: {
        en: "Optimal scheduling for video streaming in packet loss network",
        ua: "Складання оптимального розкладу передачі пакетів потокового відео в мережі з втратами даних",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2007_st17": {
      authors: {
        en: "Marchenko V.A.",
        ua: "Марченко В.А.",
      },
      title: {
        en: "The formalized technology of designing of systems of the information protection",
        ua: "Формалізована технологія проектування системи захисту інформації",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2007_st18": {
      authors: {
        en: "Kuk J., Lavrikova H.",
        ua: "Кук Ю.В., Лаврікова Г.І.",
      },
      title: {
        en: "Spectral method of recognition of dynamic systems conditions",
        ua: "Спектральний метод розпізнавання станів динамічних систем",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2007_st19": {
      authors: {
        en: "Kalenchuk-Porkhanova A., Vakal L.",
        ua: "Каленчук-Порханова А.О., Вакал Л.П.",
      },
      title: {
        en: "Constructing the best uniform approximations for many-variables functions",
        ua: "Побудова найкращих рівномірних наближень функцій багатьох змінних",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2007_st20": {
      authors: {
        en: "Ivaneshkin A.I.",
        ua: "Іванєшкін А.І.",
      },
      title: {
        en: "Modification of distances matrix in a method of branches and borders to optimaze the structure of demands queue",
        ua: "Модифікація матриці відстаней методу гілок і границь у вирішенні питання оптимізації структури черги заявок",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2007_st21": {
      authors: {
        en: "Boyun V.P., Sabelnikov Y.A.",
        ua: "Боюн В.П., Сабельніков Ю.А.",
      },
      title: {
        en: "Contour form comparison of the two-dimentional images of the objects",
        ua: "Порівняння двомірних зображень об'єктів за формою їх контурів",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
  },
  2006: {
    "2006_zmist": {
      authors: {
        en: "",
        ua: "",
      },
      title: {
        en: "Table of contents",
        ua: "Зміст",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2006_st1": {
      authors: {
        en: "Bruhovich E.I.",
        ua: "Брюхович Е.І.",
      },
      title: {
        en: "To issue of truth of affirmation in deductive theories",
        ua: "До питання про істинність тверджень в дедуктивних теоріях",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2006_st2": {
      authors: {
        en: "Kuk J., Lavrikova H.",
        ua: "Кук Ю.В., Лаврікова Е.І.",
      },
      title: {
        en: "New knowledge acquisition system on the basis of structural-predicate model of knowledge",
        ua: "Система отримання нових знань на основі структурно-предикативної моделі знань",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2006_st3": {
      authors: {
        en: "Petrenko N.G.",
        ua: "Петренко М.Г.",
      },
      title: {
        en: "Features of development knowledge-oriented of the linguistic processor",
        ua: "Особливості розробки знання-орієнтованого лінгвістичного процесора",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2006_st4": {
      authors: {
        en: "Kondratov V.T.",
        ua: "Кондратов В.Т.",
      },
      title: {
        en: "Redundant measurements theory",
        ua: "Теорія надлишкових вимірювань",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2006_st5": {
      authors: {
        en: "Semesenko M.P., Tymashov O.O.",
        ua: "Семесенко М.П., Тимашов А.А.",
      },
      title: {
        en: "Measuring complex as the time-space filter",
        ua: "Вимірювальний комплекс як просторово-часовий фільтр",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2006_st6": {
      authors: {
        en: "Bezverbnyi I.A.",
        ua: "Безвербний І.А.",
      },
      title: {
        en: "The one-tone signal digital phase analysis numerical and analytical method",
        ua: "Чисельно-аналітичний метод цифрового фазового аналізу однотональних сигналів",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2006_st7": {
      authors: {
        en: "Budnyk M.M., Lebyedyeva T.S., Shpylovyy P.B., Yakopov G.V.",
        ua: "Будник Н.Н., Лєбєдєва Т.С., Шпилевий П.Б., Якопов Г.В.",
      },
      title: {
        en: "Influenсе of proximity effect on the properties of superconductive tunnel junctions",
        ua: "Вплив ефекту близькості на властивості надпровідних тунельних переходів",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2006_st8": {
      authors: {
        en: "Vakal L.",
        ua: "Вакал Л.П.",
      },
      title: {
        en: "Uniform piecewise polynomial approximation",
        ua: "Рівномірне кусково-поліноміальне наближення",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2006_st9": {
      authors: {
        en: "Vasylyev S.V., Gupal A.M.",
        ua: "Васильєв С.В., Гупал А.М.",
      },
      title: {
        en: "Features of bases disposition in dna research using cluster computer",
        ua: "Дослідження особливостей запису основ в ДНК на кластерному комп'ютері",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2006_st10": {
      authors: {
        en: "Barkalov A.A., Krasichkov A.A., Matvienko A.V.",
        ua: "Баркалов А.А., Красічков А.А., Матвієнко А.В.",
      },
      title: {
        en: "The realization of the moore automation on FPGA as a petri network",
        ua: "Реалізація автомата Мура на FPGA у вигляді мережі Петрі",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2006_st11": {
      authors: {
        en: "Palagin A.V., Alishov N.I., Marchenko V.A., Shirokov V.A.",
        ua: "Палагін О.В., Алішов Н.І., Марченко В.А., Широков В.А.",
      },
      title: {
        en: "Technology of protection of big databases from not authorized copying",
        ua: "Технології захисту великих баз даних від несанкціонованого копіювання",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2006_st12": {
      authors: {
        en: "Petrishina O.V.",
        ua: "Петришина О.В.",
      },
      title: {
        en: "Technology of realization of universal plural access",
        ua: "Технологія реалізації універсального множинного доступу",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2006_st13": {
      authors: {
        en: "Oksyuk O.A.",
        ua: "Оксюк О.А.",
      },
      title: {
        en: "Optimized video streaming in networks with limited and time-varying bandwidth",
        ua: "Оптимізація передачі потокового відео по мережі з пропускною здатністю, близькою до швидкості бітового потоку відеозображення",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2006_st14": {
      authors: {
        en: "Sevruk A.O.",
        ua: "Севрук А.О.",
      },
      title: {
        en: "Developing methods to construction stream cipher",
        ua: "Розробка методів побудови потокових шифрів",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2006_st15": {
      authors: {
        en: "Gladun A.Y.",
        ua: "Гладун А.Я.",
      },
      title: {
        en: "The analysis of stochastic models of performance for wireless local networks IEEE 802.11",
        ua: "Аналіз стохастичних моделей продуктивності бездротових локальних мереж (БЛМ) ІЕЕЕ 802.11",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2006_st16": {
      authors: {
        en: "Jakovlev Y.S., Kurzantseva L.I.",
        ua: "Яковлєв Ю.С., Курзанцева Л.І.",
      },
      title: {
        en: "About application of ontology for construction of model of user of informative systems",
        ua: "Про застосування онтології для побудови моделі користувача інформаційних систем",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2006_st17": {
      authors: {
        en: "Pleskach V.L., Rogushina Y.V.",
        ua: "Плескач В.Л., Рогушина Ю.В.",
      },
      title: {
        en: "Ontological approach for representation of knowledge in multiagent systems of remote education",
        ua: "Онтологічний підхід до подання знань в мультиагентних системах дистанційної освіти",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2006_st18": {
      authors: {
        en: "Khodakovskyi M.I.",
        ua: "Ходаковський М.І.",
      },
      title: {
        en: "The application of elements of information nanotechnologies of molecular structures of memory of subsystems natural intelligence in computer education systems",
        ua: "Використання елементів інформаційних нанотехнологій молекулярних структур пам'яті підсистем природного інтелекту в комп'ютерних навчаючих системах",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2006_st19": {
      authors: {
        en: "Romanov V.O., Galelyuka I.B.",
        ua: "Романов В.О., Галелюка І.Б.",
      },
      title: {
        en: "Methods of perfecting of performances of microelectronic components and analysis of their efficiency with virtual evaluation boards",
        ua: "Методи удосконалення параметрів мікроелектронних компонентів і аналіз їх ефективності за допомогою віртуальних оціночних плат",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2006_st20": {
      authors: {
        en: "Lukash S.I., Kolesnitskiy O.K., Vojtovich I.D.",
        ua: "Лукаш С.І., Колесницький О.К., Войтович І.Д.",
      },
      title: {
        en: "Method and technology of the smell analysis of objects for environment and medical diagnostic",
        ua: "Техніка і технологія аналізу об'єктів для екологічної і медичної діагностики за запахом",
      },
      language: {
        // en:"ukrainian",
        // ua:"українська",
        en: "russian",
        ua: "російська",
      },
    },
    "2006_st21": {
      authors: {
        en: "Mashkin V.Y., Mashkina I.V.",
        ua: "Машкін В.Й., Машкіна І.В.",
      },
      title: {
        en: "Technical readiness control of equipment in the enterprise information system",
        ua: "Управління технічною готовністю обладнання в рамках інформаційної системи підприємства",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
    "2006_st22": {
      authors: {
        en: "Timashova L.",
        ua: "Тімашова Л.А.",
      },
      title: {
        en: "The virtual technologies of industrial plants",
        ua: "Віртуальні технології індустріальних підприємств",
      },
      language: {
        en: "ukrainian",
        ua: "українська",
        // en:"russian",
        // ua:"російська",
      },
    },
  },
};

export default zbirnykTextArray;
