// U+FEFF at the beginning

/*eslint unicode-bom: ["error", "always"]*/

const zbirnykText = {
  "zbir-content-welcome": {
    en: "'Computer means, networks and systems' (Proceedings of the Institute)",
    ua: "'Комп'ютерні засоби, мережі та системи' (Збірник праць Інституту)",
  },
  "zbir-svid": {
    en: "Certificate about state registration KB № 6541, 19.09.2002",
    ua: "Свідоцтво про державну реєстрацію KB № 6541 від 19.09.2002",
  },
  "zbir-fahov": {
    en: "Proceedings of Institute 'Computer means, networks and systems' are included in the list of professional publications of Ukraine",
    ua: "Збірник 'Комп'ютерні засоби, мережі та системи' внесено до переліку фахових видань України",
  },
  "zbir-bibl": {
    en: "Proceedings of Institute are placed on the site of V.I.Vernadsjkyy National library of Ukraine at the link ",
    ua: "Збірник розміщено на сайті Національної бібліотеки України імені В.І.Вернадського за посиланням ",
  },
  "zbir-search": {
    en: "Enter text (no less than 3 chars) to search",
    ua: "Введіть текст (не менше 3 символів) для пошуку",
  },
  "zbir-search_btn": {
    en: "Search",
    ua: "Пошук",
  },
  "zbir-select": {
    en: "or select year to dispaly articles",
    ua: "або виберіть рік для відображення збірника",
  },
};

export default zbirnykText;

/*
  "":{
    en: "",
    ua: "",
  },
  */
