import { useContext, useEffect } from "react";
import { CurrentLanguageContext } from "../App";

import Text from "../lng/lng_zbirnyk_array";

// const searchTitle = document.getElementById("content__search-title");
// const listPapers = document.getElementById("content__zbirnyk-list");

function getZbirnyk(currentLanguage) {
  const listYears = document.getElementById("content__zbirnyk-years");

  listYears.innerHTML = "";

  for (let i = 2019; i >= 2006; i--) {
    let year = document.createElement("button");
    year.innerHTML = `${i}`;
    year.id = i;
    year.classList.add("content__btn-year");
    year.addEventListener("click", () => {
      showZbirnyk(i, currentLanguage);
    });
    listYears.append(year);
  }
  // showZbirnyk(yearToShow, currentLanguage);

  const textSearch = document.getElementById("text__search");
  const btnSearch = document.getElementById("btn__search");
  btnSearch.addEventListener("click", () => {
    let textT = textSearch.value;
    showZbirnykSearch(textT, currentLanguage);
    setTimeout(() => {
      textSearch.value = "";
    }, "200");
  });
}

function showZbirnykSearch(text, currentLanguage) {
  const searchTitle = document.getElementById("content__search-title");
  const listPapers = document.getElementById("content__zbirnyk-list");

  searchTitle.innerText = "";
  listPapers.innerText = "";
  let st = "";
  if (text.length === 0) {
    st = "Search text is empty";
  } else if (text.length > 0 && text.length < 3) {
    st = `Search text '${text}' is less than 3 chars`;
  } else {
    st = `Results for searching '${text}'`;
    listPapers.append(searchText(text, currentLanguage));
  }
  searchTitle.innerText = st;
}

function searchText(text, currentLanguage) {
  let olList = document.createElement("ol");
  olList.classList.add("content__list-publications-ol");
  for (const year in Text) {
    for (const stat in Text[year]) {
      if (
        Text[year][stat]["authors"]["en"].toLowerCase().includes(text.toLowerCase()) ||
        Text[year][stat]["title"]["en"].toLowerCase().includes(text.toLowerCase())
      ) {
        olList.prepend(showRow(Text[year][stat], stat, currentLanguage, "en", true));
      }
      if (
        Text[year][stat]["authors"]["ua"].toLowerCase().includes(text.toLowerCase()) ||
        Text[year][stat]["title"]["ua"].toLowerCase().includes(text.toLowerCase())
      ) {
        olList.prepend(showRow(Text[year][stat], stat, currentLanguage, "ua", true));
      }
    }
  }
  return olList;
}

function showZbirnyk(year, currentLanguage) {
  const searchTitle = document.getElementById("content__search-title");
  const listPapers = document.getElementById("content__zbirnyk-list");

  searchTitle.innerText = "";
  listPapers.innerText = "";
  let titleText = "";

  switch (currentLanguage) {
    case "en": {
      titleText = `Proceedings for ${year} year`;
      break;
    }
    case "ua": {
      titleText = `Збірник за ${year} рік`;
      break;
    }
    default: {
      titleText = `Proceedings for ${year} year`;
      break;
    }
  }
  searchTitle.innerText = titleText;
  // const currentYear = document.getElementById(year);
  // currentYear.classList.add("content__year_active");

  //listPapers.textContent = `Збірник за ${year} ${currentLanguage}`;
  //listPapers.textContent = Text[2018]["2018_st1"]["authors"][currentLanguage] + year;

  const yearZbirnyk = Text[year];
  let olList = document.createElement("ol");
  olList.classList.add("content__list-publications-ol");
  for (const stat in yearZbirnyk) {
    olList.append(showRow(yearZbirnyk[stat], stat, currentLanguage, currentLanguage, false));
  }

  listPapers.append(olList);
}

function showRow(stat, statName, currentLanguage, paperLanguage, showYear) {
  let liList = document.createElement("li");

  let spanTitle = document.createElement("span");
  spanTitle.classList.add("content__list-title", "content--subhead-color");
  spanTitle.innerText = stat["title"][paperLanguage];
  liList.append(spanTitle);
  let spanAuthors = document.createElement("span");
  spanAuthors.classList.add("content__list-authors");
  spanAuthors.innerText = stat["authors"][paperLanguage];
  liList.append(spanAuthors);

  let yearText = "",
    linkText = "";
  switch (currentLanguage) {
    case "en": {
      yearText = "Publication year: ";
      linkText = "Link: ";
      break;
    }
    case "ua": {
      yearText = "Рік публікації: ";
      linkText = "Посилання: ";
      break;
    }
    default: {
      yearText = "Publication year: ";
      linkText = "Link: ";
      break;
    }
  }

  if (showYear) {
    let spanYear = document.createElement("span");
    spanYear.classList.add("content__descr");
    spanYear.innerText = yearText + statName.slice(0, 4);
    liList.append(spanYear);
  }

  let nameFile = statName + ".pdf";
  let spanLink = document.createElement("span");
  spanLink.classList.add("content__list-link");
  spanLink.innerHTML = `${linkText} <a class="content__list-link-a" href="../kzms/${
    nameFile.slice(0, 4) + "/" + nameFile
  }" target="_blank">${nameFile}</a>`;

  liList.append(spanLink);

  return liList;
}

export default function GetZbirnyk() {
  const { currentLanguage } = useContext(CurrentLanguageContext);

  useEffect(() => {
    getZbirnyk(currentLanguage);
  }, []);

  useEffect(() => {
    getZbirnyk(currentLanguage);
  }, [currentLanguage]);

  // useEffect(() => {
  //   showZbirnyk(2019, currentLanguage);
  // }, [currentLanguage]);

  return null;
}
