import { useContext, useEffect } from "react";
import { CurrentLanguageContext } from "../App";
import GetZbirnyk from "../utils/getZbirnyk";

import Text from "../lng/lng_zbirnyk";

function Zbirnyk() {
  const { currentLanguage } = useContext(CurrentLanguageContext);

  useEffect(() => {
    for (const key in Text) {
      const elem = document.querySelector(`[data-lang=${key}]`);
      if (elem) {
        elem.textContent = Text[key][currentLanguage];
      }
    }
  }, [currentLanguage]);

  return (
    <main className="content">
      <div className="container">
        <div className="content__inner">
          <GetZbirnyk />
          <div className="content__title" data-lang="zbir-content-welcome">
            'Computer means, networks and systems' (Proceedings of the Institute)
          </div>
        </div>
        <div className="content__descr">ISSN 1817-9908</div>
        <div className="content__descr" data-lang="zbir-svid">
          Certificate about state registration KB № 6541, 19.09.2002
        </div>
        <div className="content__descr" data-lang="zbir-fahov">
          Proceedings of Institute 'Computer means, networks and systems' are included in the list of professional
          publications of Ukraine
        </div>
        <div className="content__descr">
          <span data-lang="zbir-bibl">
            Proceedings of Institute are placed on the site of V.I.Vernadsjkyy National library of Ukraine at the link
          </span>
          <a className="content__site" href="http://nbuv.gov.ua/j-tit/Kzms" target="_blank" rel="noreferrer">
            http://nbuv.gov.ua/j-tit/Kzms
          </a>
        </div>
        <div className="content__descr" data-lang="zbir-search">
          Enter text (no less than 3 chars) to search
        </div>
        <div className="content__descr">
          <input className="content__text-search" id="text__search" type="text" placeholder="Enter text" />
          <button className="content__btn-search" id="btn__search" data-lang="zbir-search_btn">
            Search
          </button>
        </div>
        <div className="content__descr" data-lang="zbir-select">
          or select year to dispaly articles
        </div>
        <div className="content__descr content__up_bottom" id="content__zbirnyk-years">
          2006 2007 2008 2009 2010 2011 2012 2013 2014 2015 2016 2017 2018 2019
        </div>
        <div className="content__descr content--subhead-color content--subhead-fs" id="content__search-title"></div>
        <div className="content__descr" id="content__zbirnyk-list"></div>
      </div>
    </main>
  );
}

export default Zbirnyk;
